import React from "react";
import { Product } from "../../../domain/models/product";
import { Button, ButtonColor, ButtonType } from "../../ga-components/buttons";
import { CardSubHeadline, CardSupportingText } from "../../ga-components/cards";
import { CartState } from "../../providers/cart/CartContext";
import { CartAction } from "../../providers/cart/CartProvider";
import withCart from "../../providers/cart/withCart";

type Props = {
  product: Product;
  cart: CartState;
  cartOperationEventEmitter: Function;
};

const ProductItem: React.FC<Props> = ({
  product,
  cart,
  cartOperationEventEmitter,
}) => {
  const { type } = cart;

  const removeProductFromCart = () => {
    cartOperationEventEmitter({
      type: CartAction.REMOVE_PRODUCT,
      payload: { product: product, type: type },
    });
  };
  return (
    <li className="flex py-6 border-b">
      <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
        <img
          src={product.image.path}
          className="h-full w-full object-cover object-center"
        />
      </div>
      <div className="ml-4 flex flex-1 flex-col text-left">
        <div>
          <div className="flex justify-between text-base font-medium text-gray-900">
            <h3>
              <CardSubHeadline>{product.name}</CardSubHeadline>
            </h3>
          </div>
          <CardSupportingText>{product.description}</CardSupportingText>
        </div>
        <div className="flex items-end justify-between text-sm items-center pt-4">
          <div>
            <p className="text-gray-500">{`Qty: ${product.quantity}`}</p>
          </div>
          <div>
            <Button
              text="Remove"
              className="pr-4"
              type={ButtonType.OUTLINED}
              color={ButtonColor.DANGER}
              onClick={removeProductFromCart}
            />
          </div>
        </div>
      </div>
    </li>
  );
};

export default withCart(ProductItem);
