import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OrderItem } from "../../../domain/models/order-item";
import { pageRoutes } from "../../../routes";
import {
  Card,
  CardBody,
  CardColor,
  CardSubHeadline,
  CardSupportingText,
} from "../../ga-components/cards";
import Clickable from "../../ga-components/clickable";
import { CartState } from "../../providers/cart/CartContext";
import withCart from "../../providers/cart/withCart";
import { Product } from "../../../domain/models/product";
import { Address } from "../../../domain/models/address";
import { CreateSubscription } from "../../../domain/usages/create-subscription";
import { Subscription } from "../../../domain/models/subscription";
import { Result } from "../../../domain/models/result";
import Swal from "sweetalert2";
import { Icon, IconColor } from "../../ga-components/typography/icon";
import { IconSize } from "../../ga-components/typography/icon/icon-size";

type Props = {
  token?: string;
  cart: CartState;
  duration: number;
  expected_delivery_date: number;
  disabled?: boolean;
  cartOperationEventEmitter: Function;
  createSubscription: CreateSubscription;
};

const SubscriptionButton: React.FC<Props> = ({
  token,
  cart,
  duration,
  expected_delivery_date,
  disabled = false,
  cartOperationEventEmitter,
  createSubscription,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const {
    type,
    cart: { products, address },
  } = cart;

  const onClick = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton:
          "bg-primary rounded-lg pr-6 pl-4 h-12 text-sm text-white",
        cancelButton:
          "bg-red-500 rounded-lg pr-6 pl-4 h-12 text-sm text-white ml-2",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Disclaimer!",
        html: "<p style='text-align: justify'>I declare that am a registered medical practitioner and I am licensed to prescribe the drugs that I have requested Cipla Limited to supply the samples. The said samples will be used exclusively for free distribution to my patients. I shall store the drugs under my custody and freely distribute the same under by personal supervision. I irrevocably undertake to indemnify and keep Cipla Limited, its directors, officers, employees and its employees indemnified from all costs, damages and losses that it may suffer as a result misuse of the said Drug.</p>",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Yes Confirm!",
        cancelButtonColor: "#d33",
      })
      .then(async (result) => {
        if (result.value) {
          setLoading(true);
          placeSubscription(prepareData());
        }
      });
  };

  const placeSubscription = useCallback(async (subscription: Subscription) => {
    let result: Result = await createSubscription.create(subscription);
    if (result.success) {
      Swal.fire({
        icon: "success",
        title: "You Subscription request is placed successfully.",
        showConfirmButton: false,
        timer: 1500,
      });
      goToSubscriptions();
    } else if (result.errors) {
      Swal.fire({
        icon: "error",
        title: result.errors.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }, []);

  const prepareData = () => {
    let subscription: Subscription = {
      duration: duration,
      expected_delivery_date: expected_delivery_date,
      address: {
        type: address.type,
        pin_code: address.pin_code,
        line: address.line,
        landmark: address.landmark,
        area: address.area,
        district: address.district,
        state: address.state,
        country: address.country,
      } as Address,
      items: products
        .filter((p: Product) => {
          return p.price;
        })
        .map((p: Product) => {
          return {
            code: p.price?.uom.sku_code,
            sku_code: p.price?.uom.sku_code,
            item: p.name,
            description: p.description,
            quantity: p.quantity,
            unit_price: 0,
            uom: p.price?.uom.name,
            discount: 0,
            order_token: "",
            product_id: p.id,
            dept_code: p.dept_code,
            org_code: p.org_code,
          } as OrderItem;
        }),
    };
    return subscription;
  };

  const goToSubscriptions = () => {
    navigate(pageRoutes.subscriptions);
  };

  return (
    <Clickable onClick={onClick} disabled={disabled}>
      <Card
        color={disabled ? CardColor.DISABLED : CardColor.SECONDARY}
        className="rounded-none"
      >
        <CardBody className="p-2 text-center">
          <div className="flex justify-center m-auto">
            <div className="m-auto">
              {loading && (
                <span className="w-4 ">
                  <Icon
                    icon="loading"
                    spin={true}
                    color={IconColor.WHITE}
                    size={IconSize.xl}
                  />
                </span>
              )}
            </div>
            <div className="grow items-center text-center">
              <CardSubHeadline className="text-white text-sm">
                Confirm & Create Subscription
              </CardSubHeadline>
              <CardSupportingText className="text-white ">
                Click here to create Subscription
              </CardSupportingText>
            </div>
          </div>
        </CardBody>
      </Card>
    </Clickable>
  );
};

export default withCart(SubscriptionButton);
