import React from "react";

type Props = {
  size: number;
};
const CertificateArtwork: React.FC<Props> = ({ size }) => {
  const heightMultiplier = 0.542;
  const widthMultiplier = 0.637;

  const height = size * heightMultiplier;
  const width = size * widthMultiplier;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      width={width}
      height={height}
      viewBox="0 0 917.11484 517.75921"
    >
      <path
        d="M285.71989,426.20676a69,69,0,0,1,0-138h628a69,69,0,0,1,0,138Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#e6e6e6"
      />
      <path
        d="M285.71989,297.20676a60,60,0,0,0,0,120h628a60,60,0,0,0,0-120Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#fff"
      />
      <rect x="208.22204" y="106.08655" width="2" height="120" fill="#e6e6e6" />
      <rect x="333.05507" y="106.08655" width="2" height="120" fill="#e6e6e6" />
      <rect x="458.05507" y="106.08655" width="2" height="120" fill="#e6e6e6" />
      <rect x="582.05504" y="106.08655" width="2" height="120" fill="#e6e6e6" />
      <rect x="707.05504" y="106.08655" width="2" height="120" fill="#e6e6e6" />
      <path
        d="M383.89735,339.20676a2.89562,2.89562,0,1,0,0,5.79123h58.73957a2.89562,2.89562,0,0,0,.01129-5.79123H383.89735Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#e6e6e6"
      />
      <path
        d="M383.89735,354.20676a2.89562,2.89562,0,1,0,0,5.79123h58.73957a2.89562,2.89562,0,0,0,.01129-5.79123H383.89735Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#e6e6e6"
      />
      <path
        d="M383.89735,369.20676a2.89562,2.89562,0,1,0,0,5.79123h58.73957a2.89562,2.89562,0,0,0,.01129-5.79123H383.89735Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#e6e6e6"
      />
      <path
        d="M507.89735,339.20676a2.89562,2.89562,0,1,0,0,5.79123h58.73957a2.89562,2.89562,0,1,0,0-5.79123Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#00bfa6"
      />
      <path
        d="M507.89735,354.20676a2.89562,2.89562,0,1,0,0,5.79123h58.73957a2.89562,2.89562,0,1,0,0-5.79123Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#00bfa6"
      />
      <path
        d="M507.89735,369.20676a2.89562,2.89562,0,1,0,0,5.79123h58.73957a2.89562,2.89562,0,1,0,0-5.79123Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#00bfa6"
      />
      <path
        d="M632.89735,339.20676a2.89562,2.89562,0,0,0,0,5.79123h58.73957a2.89562,2.89562,0,1,0,0-5.79123H632.89735Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#e6e6e6"
      />
      <path
        d="M632.89735,354.20676a2.89562,2.89562,0,0,0,0,5.79123h58.73957a2.89562,2.89562,0,1,0,0-5.79123H632.89735Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#e6e6e6"
      />
      <path
        d="M632.89735,369.20676a2.89562,2.89562,0,0,0,0,5.79123h58.73957a2.89562,2.89562,0,1,0,0-5.79123H632.89735Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#e6e6e6"
      />
      <path
        d="M757.39735,339.20676a2.89562,2.89562,0,0,0,0,5.79123h58.73957a2.89562,2.89562,0,1,0,0-5.79123H757.39735Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#e6e6e6"
      />
      <path
        d="M757.39735,354.20676a2.89562,2.89562,0,0,0,0,5.79123h58.73957a2.89562,2.89562,0,1,0,0-5.79123H757.39735Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#e6e6e6"
      />
      <path
        d="M757.39735,369.20676a2.89562,2.89562,0,0,0,0,5.79123h58.73957a2.89562,2.89562,0,1,0,0-5.79123H757.39735Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#e6e6e6"
      />
      <path
        d="M878.89735,339.20676a2.89562,2.89562,0,0,0,0,5.79123h58.73957a2.89562,2.89562,0,1,0,0-5.79123H878.89735Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#e6e6e6"
      />
      <path
        d="M878.89735,354.20676a2.89562,2.89562,0,0,0,0,5.79123h58.73957a2.89562,2.89562,0,1,0,0-5.79123H878.89735Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#e6e6e6"
      />
      <path
        d="M878.89735,369.20676a2.89562,2.89562,0,0,0,0,5.79123h58.73957a2.89562,2.89562,0,1,0,0-5.79123H878.89735Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#e6e6e6"
      />
      <path
        d="M258.89735,339.20676a2.89562,2.89562,0,0,0,0,5.79123h58.73957a2.89562,2.89562,0,0,0,.01129-5.79123H258.89735Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#e6e6e6"
      />
      <path
        d="M258.89735,354.20676a2.89562,2.89562,0,0,0,0,5.79123h58.73957a2.89562,2.89562,0,0,0,.01129-5.79123H258.89735Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#e6e6e6"
      />
      <path
        d="M258.89735,369.20676a2.89562,2.89562,0,0,0,0,5.79123h58.73957a2.89562,2.89562,0,0,0,.01129-5.79123H258.89735Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#e6e6e6"
      />
      <circle cx="396.17308" cy="47.38212" r="47.38211" fill="#00bfa6" />
      <polygon
        points="396.173 124.408 381.357 98.747 366.542 73.086 396.173 73.086 425.803 73.086 410.988 98.747 396.173 124.408"
        fill="#00bfa6"
      />
      <circle cx="396.55522" cy="47.08636" r="17" fill="#fff" />
      <path
        d="M1028.33253,366.95044a1.51835,1.51835,0,0,1-1.07421-.44513l-8.20459-8.20456a1.5192,1.5192,0,1,1,2.14849-2.14847l6.95234,6.9523,12.32312-16.80444a1.51945,1.51945,0,0,1,2.45056,1.79712l-13.37036,18.23239a1.52068,1.52068,0,0,1-1.1095.61664C1028.40968,366.94925,1028.37086,366.95044,1028.33253,366.95044Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#00bfa6"
      />
      <path
        d="M161.56779,366.7184a1.51937,1.51937,0,0,1-1.07423-2.5936l16.19371-16.19311a1.5192,1.5192,0,0,1,2.14848,2.14846L162.642,366.27327A1.51445,1.51445,0,0,1,161.56779,366.7184Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#00bfa6"
      />
      <path
        d="M177.76129,366.71838a1.51441,1.51441,0,0,1-1.07423-.44512l-16.19312-16.19312a1.5192,1.5192,0,0,1,2.14849-2.14847l16.19308,16.19315a1.51938,1.51938,0,0,1-1.07423,2.5936Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#00bfa6"
      />
      <path
        d="M729.11811,528.17616a10.74269,10.74269,0,0,1-3.18648-16.16149l-13.88948-96.85391,23.35325.64308,6.30958,95.002a10.8009,10.8009,0,0,1-12.58687,17.3703Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#a0616a"
      />
      <path
        d="M708.99851,430.96181a4.81172,4.81172,0,0,1-1.7299-3.61867l-.39188-24.17254a13.37737,13.37737,0,0,1,26.38483-4.433l7.41286,23.09354a4.81688,4.81688,0,0,1-3.80223,6.22448l-24.00326,3.96054A4.81147,4.81147,0,0,1,708.99851,430.96181Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#00bfa6"
      />
      <polygon
        points="523.354 504.84 511.095 504.84 505.262 457.552 523.357 457.552 523.354 504.84"
        fill="#a0616a"
      />
      <path
        d="M667.64556,707.84462l-39.53052-.00146v-.5a15.38605,15.38605,0,0,1,15.38647-15.38623h.001l24.1438.001Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#2f2e41"
      />
      <polygon
        points="592.354 504.84 580.095 504.84 574.262 457.552 592.357 457.552 592.354 504.84"
        fill="#a0616a"
      />
      <path
        d="M736.64556,707.84462l-39.53052-.00146v-.5a15.38605,15.38605,0,0,1,15.38647-15.38623h.001l24.1438.001Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#2f2e41"
      />
      <path
        d="M716.25124,673.29674l-21.44263-113a1.45449,1.45449,0,0,0-1.46631-1.21094h-.0061a1.45375,1.45375,0,0,0-1.469,1.19922L669.57692,673.06139a4.50225,4.50225,0,0,1-4.76709,3.582l-17.42627-1.39356a4.50022,4.50022,0,0,1-4.12085-4.91113l14.6604-158.42285L732.8423,513.869l.46436.0127,7.36938,158.332a4.50042,4.50042,0,0,1-4.49487,4.71387H720.66652A4.50952,4.50952,0,0,1,716.25124,673.29674Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#2f2e41"
      />
      <circle cx="551.08211" cy="156.98831" r="24.56103" fill="#a0616a" />
      <path
        d="M656.22414,520.73619a4.49263,4.49263,0,0,1-2.4148-4.63672c3.1753-22.68848,1.15308-62.80762-1.10278-92.46777-1.5459-20.32715,12.11645-38.52539,31.78-42.33106.30615-.05859.61182-.11621.91772-.1709h.00025a39.5982,39.5982,0,0,1,33.96216,9.834,40.01956,40.01956,0,0,1,12.95556,33.30469c-2.91455,33.0625,2.26074,82.55761,3.32764,92.14355a4.55189,4.55189,0,0,1-1.46948,3.84961c-9.96778,9.09766-21.9917,12.17481-33.698,12.17481C681.55031,532.43639,663.449,524.38658,656.22414,520.73619Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#00bfa6"
      />
      <path
        d="M637.96333,528.44583a10.7427,10.7427,0,0,0,4.90809-15.72444L667.10858,417.926l-23.28672-1.87514-16.50194,93.77035a10.80091,10.80091,0,0,0,10.64341,18.62458Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#a0616a"
      />
      <path
        d="M664.47175,434.59447l-23.43727-6.522a4.81688,4.81688,0,0,1-3.10993-6.59769l9.85629-22.16113a13.37737,13.37737,0,0,1,25.75414,7.2481l-2.99231,23.98982a4.81688,4.81688,0,0,1-6.07092,4.04289Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#00bfa6"
      />
      <path
        d="M700.82091,372.32293a17.59805,17.59805,0,0,0,6.79721-1.2298c.95139-.36664,1.939-.668,2.88862-1.03489,8.38662-3.24017,13.91121-12.17188,14.10907-21.16048s-4.457-17.71287-11.36912-23.46238-15.865-8.70134-24.82986-9.38394c-9.65661-.73527-20.53026,1.7136-25.92627,9.7556-1.43632,2.14065-2.44084,4.73783-1.58975,7.32246a4.68546,4.68546,0,0,0,1.32028,2.028c2.40978,2.14394,4.81222.53191,7.26879.38948,3.37653-.19576,6.40925,2.53734,7.49925,5.73908s.61019,6.7266-.26991,9.99228a24.96206,24.96206,0,0,0-1.25825,6.07589,6.10833,6.10833,0,0,0,2.53094,5.37854c2.10991,1.27536,4.88078.53724,6.99193-.73608s3.93535-3.04372,6.21578-3.98071,5.34419-.72916,6.67149,1.34848a7.379,7.379,0,0,1,.84345,2.43522c1.18991,5.42125.91643,5.102,2.10635,10.52322Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#2f2e41"
      />
      <path
        d="M797.19291,708.87961h-254a1,1,0,0,1,0-2h254a1,1,0,0,1,0,2Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#3f3d56"
      />
      <path
        d="M169.66469,385.60256a28.5,28.5,0,1,1,28.5-28.5A28.53242,28.53242,0,0,1,169.66469,385.60256Zm0-54a25.5,25.5,0,1,0,25.5,25.5A25.52913,25.52913,0,0,0,169.66469,331.60256Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#e6e6e6"
      />
      <path
        d="M1029.77953,385.437a28.5,28.5,0,1,1,28.5-28.5A28.53242,28.53242,0,0,1,1029.77953,385.437Zm0-54a25.5,25.5,0,1,0,25.5,25.5A25.52913,25.52913,0,0,0,1029.77953,331.437Z"
        transform="translate(-141.16469 -191.12039)"
        fill="#e6e6e6"
      />
    </svg>
  );
};

export default CertificateArtwork;
