import { CartModel } from "./models/cart-model";

export enum CartType {
  sample = "sample",
  subscription = "subscription",
}

export type CartState = {
  cart: CartModel;
  type?: CartType;
  card_id?: string;
  user?: any;
  orderedSkuCodes?: string[];
  subscribedSkuCodes?: string[];
};

type CartContext = {
  state: CartState;
  dispatch: Function;
};

export default CartContext;
