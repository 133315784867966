import {
  AUTH_TOKEN_HEADER,
  AUTH_TOKEN_KEY,
  BASE_DOCTOR_API_ENDPOINT,
  BASE_ORDER_API_ENDPOINT,
} from "../../base";
import { RemoteCreateOrder } from "../../data/usercases/remote-create-order";
import { RemoteCreateSubscription } from "../../data/usercases/remote-create-subscription";
import { RemoteGetProductList } from "../../data/usercases/remote-get-product-list";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import SubscriptionPage from "../../presentation/screens/order/SubscriptionPage";

type Props = {};

export const SubscriptionPageFactory = (props: Props) => {
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN_KEY);

  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({
    [AUTH_TOKEN_HEADER]: token,
  });

  const remoteGetAddressList = new RemoteGetProductList(
    `${BASE_DOCTOR_API_ENDPOINT}/doctors/delivery-addresses`,
    axiosHttpClient
  );

  const createSubscription = new RemoteCreateSubscription(
    `${BASE_ORDER_API_ENDPOINT}/subscriptions`,
    axiosHttpClient
  );

  return (
    <SubscriptionPage
      getAddressList={remoteGetAddressList}
      createSubscription={createSubscription}
      {...props}
    />
  );
};
