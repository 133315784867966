import React from "react";
import { Price } from "../../../domain/models/price";
import { CardSubHeadline } from "../../ga-components/cards";

type Props = {
  price: Price;
  onClick: Function;
};

const UomItem: React.FC<Props> = ({ price, onClick }) => {
  return (
    <li className="flex py-6 border-b" onClick={() => onClick(price)}>
      <div className="ml-4 flex flex-1 flex-col text-left">
        <div>
          <div className="flex justify-between text-base font-medium text-gray-900">
            <CardSubHeadline>{price.uom.name}</CardSubHeadline>
          </div>
        </div>
        <div className="flex flex-1 items-end justify-between text-sm">
          <p className="text-gray-500">{`Qty: ${price.uom.max_orderable_qty}`}</p>
        </div>
      </div>
    </li>
  );
};

export default UomItem;
