import {
  AUTH_TOKEN_HEADER,
  AUTH_TOKEN_KEY,
  BASE_DOCTOR_API_ENDPOINT,
} from "../../base";
import { RemoteGetProgramUrl } from "../../data/usercases/remote-get-program-url";
import { RemoteRegisterForProgram } from "../../data/usercases/remote-register-for-program";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import ProgramPage from "../../presentation/screens/program/ProgramPage";

type Props = {};

export const ProgramPageFactory = (props: Props) => {
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN_KEY);

  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({
    [AUTH_TOKEN_HEADER]: token,
  });

  const getProgramUrl = new RemoteGetProgramUrl(
    `${BASE_DOCTOR_API_ENDPOINT}/programs/url`,
    axiosHttpClient
  );

  const registerForProgram = new RemoteRegisterForProgram(
    `${BASE_DOCTOR_API_ENDPOINT}/programs/register`,
    axiosHttpClient
  );

  return (
    <ProgramPage
      storage={storage}
      getProgramUrl={getProgramUrl}
      registerForProgram={registerForProgram}
      {...props}
    />
  );
};
