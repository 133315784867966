import { LOCATION_SERVICE_TOKEN } from "../../base";
import { Result } from "../../domain/models/result";
import { FindPostalAddressForPincode } from "../../domain/usages/find-postal-address-for-pincode";

import { HttpGetClient } from "../protocols/http/http-get-client";

export class RemoteFindPostalAddressForPincode
  implements FindPostalAddressForPincode
{
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async find(pincode: number): Promise<Result> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(":pincode", pincode.toString()),
      query: {},
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + LOCATION_SERVICE_TOKEN,
      },
    });

    return httpResponse.data;
  }
}
