import React from "react";
import { toFullDateString } from "../../../Constants";
import { Order } from "../../../domain/models/order";
import {
  Card,
  CardBody,
  CardColor,
  CardRightContent,
  CardSubHeadline,
  CardSupportingText,
} from "../../ga-components/cards";
import StatusChip from "./order-status";

type Props = {
  order: Order;
};

const OrderItem: React.FC<Props> = ({ order }) => {
  return (
    <Card color={CardColor.WHITE} className="mb-2 border">
      <CardBody className="flex justify-between">
        <CardSupportingText>#{order.id}</CardSupportingText>
        <CardSubHeadline className="">
          {toFullDateString(order.order_date)}
        </CardSubHeadline>
      </CardBody>
      {order.items.map((i) => (
        <div>
          <CardBody className="text-left pt-0 pb-0 flex justify-between">
            <div>
              <CardSubHeadline className="text-sm">
                <div className="">
                  <span>{i.item}</span>{" "}
                </div>
              </CardSubHeadline>
              <CardSupportingText className="text-xs">
                {i.description}
              </CardSupportingText>
            </div>
            <div className="w-12"></div>
            <span className="text-primary">x{i.quantity}</span>
          </CardBody>
          <CardBody className="relative h-8 pt-0">
            <CardRightContent className="mt-0 mr-0 pt-0">
              <CardSupportingText className="text-xs place-items-end">
                {order.status && <StatusChip status={order.status} />}
              </CardSupportingText>
            </CardRightContent>
          </CardBody>
        </div>
      ))}
    </Card>
  );
};

export default OrderItem;
