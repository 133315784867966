import React, { useEffect, useState } from "react";
import { Price } from "../../../domain/models/price";
import { Product } from "../../../domain/models/product";
import { Button, ButtonColor, ButtonType } from "../../ga-components/buttons";
import { CardMessage } from "../../ga-components/cards";
import { MessageType } from "../../ga-components/cards/card-message";
import QuantityButton from "../../ga-components/inputs/quantity-button";
import { Icon } from "../../ga-components/typography/icon";
import { CartState, CartType } from "../../providers/cart/CartContext";
import { CartAction } from "../../providers/cart/CartProvider";
import withCart from "../../providers/cart/withCart";
import PriceListModal from "./price-list-modal";

type CartMessageProps = {
  type: CartType;
};

const CartMessage: React.FC<CartMessageProps> = ({ type }) => {
  if (type == CartType.sample)
    return <CardMessage type={MessageType.info} message={`Added for Sample`} />;
  return (
    <CardMessage
      type={MessageType.warning}
      message={`Added for Subscription`}
    />
  );
};

type Props = {
  cart: CartState;
  cartOperationEventEmitter: Function;
  product: Product;
};

const AddToCart: React.FC<Props> = ({
  cart,
  cartOperationEventEmitter,
  product,
}) => {
  const {
    type,
    cart: { products },
    orderedSkuCodes,
    subscribedSkuCodes,
  } = cart;

  const [modalIsOpen, setIsOpen] = useState(false);
  const [cartProduct, setCartProduct] = useState<Product>();
  const [cartType, setCartType] = useState<CartType | undefined>(type);
  const [alreadyOrdered, setAlreadyOrdered] = useState(false);
  const [alreadySubscribed, setAlreadySubscribed] = useState(false);
  const [canOrder, setCanOrder] = useState(false);
  const [canSubscribe, setCanSubscribe] = useState(false);

  const addProductToCart = (type: CartType) => {
    if (product.prices.length > 1) {
      setCartType(type);
      setIsOpen(true);
      return;
    }
    product.price = product.prices[0];
    product.quantity = product.price.uom.moq;
    if (typeof product.quantity !== "number") {
      product.quantity = parseInt(product.quantity);
    }
    cartOperationEventEmitter({
      type: CartAction.ADD_PRODUCT,
      payload: { product: product, type: type },
    });
  };

  const updateProduct = (product: Product) => {
    cartOperationEventEmitter({
      type: CartAction.UPDATE_QUANTITY,
      payload: { product: product, type: cartType },
    });
  };

  const onPriceSelection = (price: Price) => {
    setIsOpen(false);
    product.price = price;
    product.quantity = product.price.uom.moq;
    if (typeof product.quantity !== "number") {
      product.quantity = parseInt(product.quantity);
    }
    cartOperationEventEmitter({
      type: CartAction.ADD_PRODUCT,
      payload: { product: product, type: cartType },
    });
  };

  const removeProductFromCart = (type: CartType) => {
    cartOperationEventEmitter({
      type: CartAction.REMOVE_PRODUCT,
      payload: { product: product, type: type },
    });
  };

  useEffect(() => {
    setCartProduct(products.find((p) => p.id == product.id));
  }, [products]);

  useEffect(() => {
    if (orderedSkuCodes) {
      let orderFound = product.prices.find(
        (price) => orderedSkuCodes.indexOf(price.uom.sku_code) >= 0
      );
      if (orderFound) {
        setAlreadyOrdered(true);
      }
    }
  }, [orderedSkuCodes]);

  useEffect(() => {
    if (subscribedSkuCodes) {
      let subscriptionFound = product.prices.find(
        (price) => subscribedSkuCodes.indexOf(price.uom.sku_code) >= 0
      );
      if (subscriptionFound) {
        setAlreadySubscribed(true);
      }
    }
  }, [subscribedSkuCodes]);

  useEffect(() => {
    const ordered: number = orderedSkuCodes ? orderedSkuCodes.length : 0;
    const totalProductsInCart = type == CartType.sample ? products.length : 0;
    let orderFlag: boolean = true;

    if (alreadyOrdered) {
      orderFlag = false;
    }
    if (ordered + totalProductsInCart >= 3) {
      orderFlag = false;
    }
    if (!product.open_to_order) {
      orderFlag = false;
    }
    setCanOrder(orderFlag);
  }, [type, alreadyOrdered, orderedSkuCodes, products]);

  useEffect(() => {
    const subscribed: number = subscribedSkuCodes
      ? subscribedSkuCodes.length
      : 0;
    const totalProductsInCart =
      type == CartType.subscription ? products.length : 0;
    let subscribeFlag: boolean = true;

    if (alreadySubscribed) {
      subscribeFlag = false;
    }
    if (subscribed + totalProductsInCart >= 3) {
      subscribeFlag = false;
    }
    if (!product.open_to_order || product.order_once) {
      subscribeFlag = false;
    }
    setCanSubscribe(subscribeFlag);
  }, [type, alreadySubscribed, subscribedSkuCodes, products]);

  if (cartProduct && type) {
    return (
      <div>
        {product.price && (
          <CardMessage
            type={
              type == CartType.sample ? MessageType.info : MessageType.warning
            }
            message={product.price.uom.name}
          />
        )}
        <div className="flex h-10">
          <div className="w-3/4 flex-none items-center">
            <QuantityButton
              value={cartProduct.quantity}
              maxQuantity={cartProduct.price?.uom.max_orderable_qty}
              minQuantity={cartProduct.price?.uom.moq}
              onChange={(quantity: number) => {
                updateProduct({ ...cartProduct, quantity: quantity });
              }}
            />
          </div>
          <div className="w-1/4 flex-none">
            <Button
              text={<Icon icon="delete" />}
              type={ButtonType.OUTLINED}
              color={ButtonColor.DANGER}
              className="rounded-none w-full"
              onClick={() => removeProductFromCart(type)}
            />
          </div>
        </div>
      </div>
    );
  }

  if (type) {
    return (
      <>
        <div>
          {((type == CartType.sample && product.sampleable == 1) ||
            (type == CartType.subscription &&
              product.sample_subscripable == 1)) && (
            <div className="flex">
              <div className="w-full flex-none">
                <Button
                  disabled={type == CartType.sample ? !canOrder : !canSubscribe}
                  text={
                    type == CartType.sample
                      ? canOrder
                        ? "Add"
                        : "Reached Limit"
                      : canSubscribe
                      ? "Subscribe"
                      : "Reached Limit"
                  }
                  type={ButtonType.FILLED}
                  color={
                    type == CartType.sample
                      ? ButtonColor.PRIMARY
                      : ButtonColor.SECONDARY
                  }
                  className="rounded-none w-full"
                  onClick={() => addProductToCart(type)}
                />
              </div>
            </div>
          )}
        </div>
        <PriceListModal
          open={modalIsOpen}
          close={setIsOpen}
          product={product}
          onClick={onPriceSelection}
        />
      </>
    );
  }

  return (
    <>
      <div>
        <div className="flex items-center">
          {product.sampleable == 1 && (
            <div
              className={`${
                product.sample_subscripable == 1 ? "w-2/4" : "w-full"
              } flex-none`}
            >
              <Button
                disabled={!canOrder}
                text={
                  <span>
                    {canOrder ? (
                      "Add"
                    ) : (
                      <span className="text-xs">Reached Limit</span>
                    )}
                  </span>
                }
                type={ButtonType.FILLED}
                color={ButtonColor.PRIMARY}
                className="rounded-none w-full"
                onClick={() => addProductToCart(CartType.sample)}
              />
            </div>
          )}
          {product.sample_subscripable == 1 && (
            <div
              className={`${
                product.sampleable == 1 ? "w-2/4" : "w-full"
              } flex-none`}
            >
              <Button
                disabled={!canSubscribe}
                text={
                  <span>
                    {canSubscribe ? (
                      "Subscribe"
                    ) : (
                      <span className="text-xs">Reached Limit</span>
                    )}
                  </span>
                }
                type={ButtonType.FILLED}
                color={ButtonColor.SECONDARY}
                className="rounded-none w-full"
                onClick={() => addProductToCart(CartType.subscription)}
              />
            </div>
          )}
        </div>
      </div>
      <PriceListModal
        open={modalIsOpen}
        close={setIsOpen}
        product={product}
        onClick={onPriceSelection}
      />
    </>
  );
};

export default withCart(AddToCart);
