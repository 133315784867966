import { BASE_DOCTOR_API_ENDPOINT, BASE_ORDER_API_ENDPOINT } from "../../base";
import { RemoteAddPanCard } from "../../data/usercases/remote-add-pan-card";
import { RemoteGetCurrentUsage } from "../../data/usercases/remote-get-current-usage";
import { RemoteGetIdentifyToken } from "../../data/usercases/remote-get-identify-token";
import { RemoteGetPanCardDetails } from "../../data/usercases/remote-get-pan-card-details";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import PanCardCheck from "../../presentation/components/pan-card/pan-card-check";

type Props = {
  setToken: (token: string) => void;
};

export const PanCardCheckFactory: React.FC<Props> = ({ setToken }) => {
  const axiosHttpClient = AxiosHttpClient.getInstance();

  const getIdentifyToken = new RemoteGetIdentifyToken(
    `${BASE_DOCTOR_API_ENDPOINT}/doctors/identityToken`,
    axiosHttpClient
  );

  const getCurrentUsage = new RemoteGetCurrentUsage(
    `${BASE_ORDER_API_ENDPOINT}/orders/utilized-limit`,
    axiosHttpClient
  );

  const getPanCardDetails = new RemoteGetPanCardDetails(
    `${BASE_DOCTOR_API_ENDPOINT}/doctors/pancard/status`,
    axiosHttpClient
  );

  const addPanCard = new RemoteAddPanCard(
    `${BASE_DOCTOR_API_ENDPOINT}/doctors/validate`,
    axiosHttpClient
  );
  return (
    <PanCardCheck
      getIdentifyToken={getIdentifyToken}
      getPanCardDetails={getPanCardDetails}
      addPanCard={addPanCard}
      getCurrentUsage={getCurrentUsage}
      setToken={setToken}
    />
  );
};
