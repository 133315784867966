import { useEffect, useState } from "react";
import OrderApp from "./OrderApp";

export default function SplashApp({}) {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 200);
  });

  return (
    <div className="flex h-screen bg-white">
      <div className="justify-center w-full">
        {loading && (
          <>
            <section className="h-full gradient-form bg-gray-200 md:h-screen">
              <div className="py-12 h-full">
                <div className="flex justify-center items-center flex-wrap h-full g-6 text-gray-800">
                  <div className="h-full">
                    <div className="block bg-white shadow-lg rounded-lg">
                      <div className="flex flex-wrap g-0">
                        <div className="flex items-center lg:rounded-r-lg rounded-b-lg lg:rounded-bl-none">
                          <div className="text-white px-4 py-6 md:p-12 md:mx-6">
                            <img
                              className="mx-auto w-48"
                              src="/cod-logo.png"
                              alt="logo"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
        {!loading && <OrderApp />}
      </div>
    </div>
  );
}
