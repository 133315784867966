import { Result } from "../../domain/models/result";
import { GenerateOtp } from "../../domain/usages/generate-otp";
import { HttpGetClient } from "../protocols/http/http-get-client";
import { HttpPostClient } from "../protocols/http/http-post-client";

export class RemoteGenerateOtp implements GenerateOtp {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}

  async generate(params: GenerateOtp.Params): Promise<Result> {
    const httpResponse = await this.httpPostClient.post({
      url: this.url,
      body: params,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      authHeaders: false,
    });

    return httpResponse.data;
  }
}
