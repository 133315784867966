import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AUTH_TOKEN_KEY } from "../../../base";
import { CATEGORY_LIST, CONNECT_OD_LOGO } from "../../../Constants";
import { JsonStorage } from "../../../data/protocols/storage/json-storage";
import { pageRoutes } from "../../../routes";
import LoadingArtwork from "../../components/common/loading";
import { TabModel } from "../../ga-components/tabs";

const FilterTabs = CATEGORY_LIST.map(
  (c) => ({ id: c.value, text: c.label, value: c.value } as TabModel)
);

type Props = {
  storage: JsonStorage;
};
const AuthPage: React.FC<Props> = ({ storage }) => {
  let { token } = useParams();
  const [querySearch] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (token) {
      storage.add(AUTH_TOKEN_KEY, token);
      setTimeout(() => {
        navigate(
          pageRoutes.authDataPull +
            "?continue=" +
            encodeURIComponent("" + querySearch.get("continue"))
        );
      }, 200);
    }
  }, []);
  return (
    <>
      <div className="flex h-screen bg-white">
        <div className="m-auto justify-center">
          <div>
            <LoadingArtwork size={400} />
            <div className="mt-2 capital text-lg text-center text-gray-500">
              Preparing something special...
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthPage;
