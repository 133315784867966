import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AUTH_DOCTOR } from "../../../base";
import { PFIZER_STEP_UP_PROGRAM } from "../../../Constants";
import { JsonStorage } from "../../../data/protocols/storage/json-storage";
import { Result } from "../../../domain/models/result";
import { GetProgramUrl } from "../../../domain/usages/get-program-url";
import { RegisterForProgram } from "../../../domain/usages/register-for-program";
import CertificateArtwork from "../../components/common/certificate";
import FormArtwork from "../../components/common/form";
import { InputField, InputVariant } from "../../ga-components/inputs";
import { Button, ButtonColor, ButtonType } from "../../ga-components/buttons";
import { NavColor, NavBar } from "../../ga-components/nav";

type ProgramFields = {
  full_name: string;
  email: string;
  mobile: string;
};

type Props = {
  storage: JsonStorage;
  getProgramUrl: GetProgramUrl;
  registerForProgram: RegisterForProgram;
};

const ProgramPage: React.FC<Props> = ({
  storage,
  getProgramUrl,
  registerForProgram,
}) => {
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const [doctor, setDoctor] = useState();
  const [loading, setLoading] = useState(false);
  const [registrationRequired, setRegistrationRequired] = useState(false);
  const [url, setUrl] = useState<string>();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid, errors },
  } = useForm<ProgramFields>({
    mode: "onChange",
    defaultValues: {
      full_name: "",
      email: "",
      mobile: "",
    },
  });

  /**
   * Go to previous page
   * If coming from mobile app go back to app
   * If coming from web go to previous page from history
   */
  let goBack = () => {
    let BackButton = (window as { [key: string]: any })["BackButton"];
    if (BackButton) BackButton.postMessage("Hello!");
    else {
      window.open("about:blank", "_self");
      window.close();
    }
  };

  /** Get url for the doctor*/
  const fetchProgramUrl = useCallback(async () => {
    let result: Result = await getProgramUrl.get({
      program_name: PFIZER_STEP_UP_PROGRAM,
    });
    if (result.url) {
      window.location.href = result.url;
    } else {
      setRegistrationRequired(true);
    }
  }, [getProgramUrl]);

  /** Register doctor for the program */
  const registerToProgram = useCallback(
    async (data: ProgramFields) => {
      setLoading(true);
      let result: Result = await registerForProgram.register({
        program_name: PFIZER_STEP_UP_PROGRAM,
        email: data.email,
      });
      if (result.url) {
        window.location.href = result.url;
      }
      setLoading(false);
    },
    [registerForProgram]
  );

  useEffect(() => {
    if (doctor) {
      fetchProgramUrl();
    }
  }, [doctor]);

  useEffect(() => {
    const doctor: any = JSON.parse(
      storage.get(AUTH_DOCTOR) ?? JSON.stringify({})
    );
    if (doctor) {
      setDoctor(doctor);
      setValue("full_name", doctor.full_name);
      setValue("mobile", doctor.mobile);
      setValue("email", doctor.email);
    }
  }, []);

  const onSubmit = (data: ProgramFields) => {
    registerToProgram(data);
  };

  return (
    <>
      <NavBar
        title={"STEP Program"}
        subTitle={""}
        color={NavColor.WHITE}
        back={true}
        onBackClick={goBack}
      />
      {url && (
        <div className="flex h-screen bg-white">
          <iframe
            src={url}
            frameBorder="0"
            marginHeight={0}
            marginWidth={0}
            width="100%"
            height="100%"
          ></iframe>
        </div>
      )}
      {!url && (
        <div className="flex h-screen bg-white">
          <div className="m-auto justify-center">
            <div>
              {!registrationRequired && (
                <div>
                  <CertificateArtwork size={400} />
                  <div className="mt-4 text-center">
                    Preparing your program...
                  </div>
                </div>
              )}
              {registrationRequired && (
                <div className="mt-2 capital text-lg text-center text-gray-500">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-4">
                      <FormArtwork size={300} className="m-auto" />
                      <div className="text-sm">
                        Please fill the form to register for the STEP program
                      </div>
                    </div>
                    <div className="mb-4">
                      <Controller
                        name="full_name"
                        control={control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <InputField
                            type="text"
                            disabled={true}
                            value={value}
                            onChange={(e: any) => {
                              if (e.target && e.target.value)
                                onChange(e.target.value);
                              else onChange("");
                            }}
                            variant={InputVariant.FILLED}
                            placeholder=""
                            error={error && error.message}
                            label="Full Name"
                          />
                        )}
                      />
                    </div>
                    <div className="mb-4">
                      <Controller
                        name="mobile"
                        control={control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <InputField
                            type="text"
                            disabled={true}
                            value={value}
                            onChange={(e: any) => {
                              if (e.target && e.target.value)
                                onChange(e.target.value);
                              else onChange("");
                            }}
                            variant={InputVariant.FILLED}
                            placeholder=""
                            error={error && error.message}
                            label="Mobile Number"
                          />
                        )}
                      />
                    </div>
                    <div className="mb-4">
                      <Controller
                        name="email"
                        control={control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <InputField
                            type="text"
                            value={value}
                            onChange={(e: any) => {
                              if (e.target && e.target.value)
                                onChange(e.target.value);
                              else onChange("");
                            }}
                            variant={InputVariant.FILLED}
                            placeholder=""
                            error={error && error.message}
                            label="Email"
                          />
                        )}
                        rules={{
                          required: {
                            value: true,
                            message: "Required",
                          },
                          pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: "Invalid email address",
                          },
                        }}
                      />
                    </div>
                    <Button
                      loading={loading}
                      className="w-full"
                      color={ButtonColor.PRIMARY}
                      type={ButtonType.FILLED}
                      text="SUBMIT"
                      onClick={handleSubmit(onSubmit)}
                    />
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProgramPage;
