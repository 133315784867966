import React from "react";
import { Price } from "../../../domain/models/price";
import UomItem from "./price-item";

type Props = {
  onSelect: Function;
  prices: Price[];
};

const PriceList: React.FC<Props> = ({ onSelect, prices }) => {
  return (
    <ul>
      {prices &&
        prices.length > 0 &&
        prices.map((price) => <UomItem price={price} onClick={onSelect} />)}
    </ul>
  );
};

export default PriceList;
