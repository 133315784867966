import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Address } from "../../../domain/models/address";
import { Result } from "../../../domain/models/result";
import { AddDeliveryAddress } from "../../../domain/usages/add-delivery-address";
import { FindPostalAddressForPincode } from "../../../domain/usages/find-postal-address-for-pincode";
import { UpdateDeliveryAddress } from "../../../domain/usages/update-delivery-address";
import { Button, ButtonColor, ButtonType } from "../../ga-components/buttons";
import { InputField, InputVariant } from "../../ga-components/inputs";
import SelectField from "../../ga-components/inputs/select-field";
import { Option } from "../../ga-components/inputs/option";
import { NavBar, NavColor } from "../../ga-components/nav";
import { CartState } from "../../providers/cart/CartContext";
import { CartAction } from "../../providers/cart/CartProvider";
import withCart from "../../providers/cart/withCart";
import { ADDRESS_TYPES } from "../../../Constants";

type AddressFormField = {
  type: string;
  pin_code: string;
  line: string;
  landmark: string;
  area: string;
  district: string;
  state: string;
  country: string;
};

type Props = {
  findPostalAddressForPincode: FindPostalAddressForPincode;
  addDeliveryAddress: AddDeliveryAddress;
  updateDeliveryAddress: UpdateDeliveryAddress;
  cart: CartState;
  cartOperationEventEmitter: Function;
};

const AddressForm: React.FC<Props> = ({
  findPostalAddressForPincode,
  addDeliveryAddress,
  updateDeliveryAddress,
  cart,
  cartOperationEventEmitter,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const {
    cart: { address },
  } = cart;
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isValid, errors },
  } = useForm<AddressFormField>({
    mode: "onChange",
    defaultValues: {
      type: address.type ? address.type : "Clinic",
      pin_code: address.pin_code,
      line: address.line,
      landmark: address.landmark,
      area: address.area,
      district: address.district,
      state: address.state,
      country: address.country ? address.country : "India",
    },
  });
  const pincode = watch("pin_code");

  const createAddress = useCallback(
    async (address: Address) => {
      setLoading(true);
      let result: Result = await addDeliveryAddress.create(address);
      setLoading(false);
      if (result.success) {
        cartOperationEventEmitter({
          type: CartAction.SET_ADDRESS,
          payload: { address: address },
        });
        navigate(-1);
      }
    },
    [addDeliveryAddress]
  );

  const updateAddress = useCallback(
    async (id: string, data: Address) => {
      setLoading(true);
      let result: Result = await updateDeliveryAddress.update(id, data);
      setLoading(false);
      if (result.success) {
        cartOperationEventEmitter({
          type: CartAction.SET_ADDRESS,
          payload: { address: address },
        });
        navigate(-1);
      }
    },
    [updateDeliveryAddress]
  );

  const fetchPostOffices = useCallback(
    async (pincode: number) => {
      let result: Result = await findPostalAddressForPincode.find(pincode);
      if (result && result.data && result.data.length > 0) {
        var postOffice = result.data[0];
        setValue("state", postOffice["state"]);
        setValue("district", postOffice["district"]);
      }
    },
    [findPostalAddressForPincode]
  );

  useEffect(() => {
    if (pincode && pincode.length == 6) {
      fetchPostOffices(parseInt(pincode));
    }
  }, [pincode]);

  const onSubmit = (data: any) => {
    if (data && isValid) {
      if (address._id) updateAddress(address._id, data);
      else createAddress(data);
    }
  };

  return (
    <>
      <NavBar
        title={"Delivery Address"}
        subTitle={""}
        color={NavColor.WHITE}
        back={true}
        leftIcon="cancel"
        onBackClick={() => navigate(-1)}
      />
      <div className="h-full text-left p-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <Controller
              name="pin_code"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputField
                  type="text"
                  value={value}
                  onChange={(e: any) => {
                    if (e.target && e.target.value) onChange(e.target.value);
                    else onChange("");
                  }}
                  variant={InputVariant.FILLED}
                  placeholder=""
                  error={error && error.message}
                  label="Pincode"
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: "Pincode is required",
                },
                pattern: {
                  value: /^[1-9]{1}[0-9]{5}$/,
                  message: "Invalid pincode",
                },
              }}
            />
          </div>
          <div className="mb-4">
            <Controller
              name="type"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <SelectField
                  value={
                    {
                      value: value ? value.toString() : undefined,
                      key: value ? value.toString() : undefined,
                    } as Option
                  }
                  onChange={(value: any) => onChange(value.value)}
                  options={ADDRESS_TYPES.map(
                    (s) =>
                      ({ key: s.name, value: s.value.toString() } as Option)
                  )}
                  variant={InputVariant.FILLED}
                  error={error && error.message}
                  placeholder="Address Type"
                  label="Choose Address Type"
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: "Required",
                },
              }}
            />
          </div>
          <div className="mb-4">
            <Controller
              name="line"
              control={control}
              defaultValue={""}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputField
                  type="text"
                  value={value}
                  onChange={(e: any) => {
                    if (e.target && e.target.value) onChange(e.target.value);
                    else onChange("");
                  }}
                  variant={InputVariant.FILLED}
                  placeholder=""
                  error={error && error.message}
                  label="Flat/Clinic/Hospital, Building/Apartment"
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: "Required",
                },
                minLength: {
                  value: 10,
                  message: "Address line should be minimum 10 characters",
                },
                maxLength: {
                  value: 40,
                  message: "Address line should be maximum 40 characters",
                },
                pattern: {
                  value: /^[-0-9A-Za-z.&,\/ ]+$/,
                  message: "Invalid address line",
                },
              }}
            />
          </div>
          <div className="mb-4">
            <Controller
              name="landmark"
              control={control}
              defaultValue={""}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputField
                  type="text"
                  value={value}
                  onChange={(e: any) => {
                    if (e.target && e.target.value) onChange(e.target.value);
                    else onChange("");
                  }}
                  variant={InputVariant.FILLED}
                  placeholder=""
                  error={error && error.message}
                  label="Landmark"
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: "Required",
                },
                minLength: {
                  value: 10,
                  message: "Address line should be minimum 10 characters",
                },
                maxLength: {
                  value: 40,
                  message: "Address line should be maximum 40 characters",
                },
                pattern: {
                  value: /^[-0-9A-Za-z.&,\/ ]+$/,
                  message: "Invalid landmark",
                },
              }}
            />
          </div>
          <div className="mb-4">
            <Controller
              name="area"
              control={control}
              defaultValue={""}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputField
                  type="text"
                  value={value}
                  onChange={(e: any) => {
                    if (e.target && e.target.value) onChange(e.target.value);
                    else onChange("");
                  }}
                  variant={InputVariant.FILLED}
                  placeholder=""
                  error={error && error.message}
                  label="Area/Colony/Street/Sector/Village"
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: "Required",
                },
                minLength: {
                  value: 3,
                  message: "Area should be minimum 3 characters",
                },
                maxLength: {
                  value: 40,
                  message: "Area should be maximum 40 characters",
                },
                pattern: {
                  value: /^[-0-9A-Za-z.&,\/ ]+$/,
                  message: "Invalid area",
                },
              }}
            />
          </div>
          <div className="mb-4">
            <Controller
              name="district"
              control={control}
              defaultValue={""}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputField
                  type="text"
                  value={value}
                  onChange={(e: any) => {
                    if (e.target && e.target.value) onChange(e.target.value);
                    else onChange("");
                  }}
                  variant={InputVariant.FILLED}
                  placeholder=""
                  error={error && error.message}
                  label="Town/City"
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: "Required",
                },
                minLength: {
                  value: 3,
                  message: "District should be minimum 3 characters",
                },
                maxLength: {
                  value: 40,
                  message: "Address line should be maximum 40 characters",
                },
                pattern: {
                  value: /^[-0-9A-Za-z.& ]+$/,
                  message: "Invalid district",
                },
              }}
            />
          </div>
          <div className="mb-4">
            <Controller
              name="state"
              control={control}
              defaultValue={""}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputField
                  disabled
                  type="text"
                  value={value}
                  onChange={(e: any) => {
                    if (e.target && e.target.value) onChange(e.target.value);
                    else onChange("");
                  }}
                  variant={InputVariant.FILLED}
                  placeholder=""
                  error={error && error.message}
                  label="State"
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: "Required",
                },
                minLength: {
                  value: 3,
                  message: "State should be minimum 3 characters",
                },
                maxLength: {
                  value: 25,
                  message: "Address line should be maximum 40 characters",
                },
                pattern: {
                  value: /^[-0-9A-Za-z.& ]+$/,
                  message: "Invalid state",
                },
              }}
            />
          </div>
          <Button
            loading={loading}
            className="w-full"
            color={ButtonColor.PRIMARY}
            type={ButtonType.FILLED}
            text="SUBMIT"
            onClick={onSubmit}
          />
        </form>
      </div>
    </>
  );
};

export default withCart(AddressForm);
