import React from "react";
import ProductPage from "./presentation/screens/order/ProductPage";

export const pageRoutes = {
  login: "/",
  auth: "/auth/forToken/:token",
  authDataPull: "/auth/pull-data",
  cards: "/cards",
  orders: "/orders",
  subscriptions: "/subscriptions",
  products: "/products",
  subscription_summary: "/subscriptions/summary",
  cart: "/cart",
  address: "/address",
  employee_general_support: "/support/employee-general",
  employee_support: "/support/employee-login",
  doctor_general_support: "/support/doctor-general",
  doctor_support: "/support/doctor-login",
  wallet: "/wallet",
  programs: "/programs",
  survey: "/survey",
};

export interface AppRoute {
  path: string;
  name: string;
  component: React.FC;
  exact: boolean;
}

let indexRoutes: AppRoute[] = [
  {
    path: pageRoutes.orders,
    name: "Orders Page",
    component: ProductPage,
    exact: true,
  } as AppRoute,
  {
    path: pageRoutes.products,
    name: "Products Page",
    component: ProductPage,
    exact: true,
  } as AppRoute,
  {
    path: pageRoutes.subscription_summary,
    name: "Data entry admin dashboard",
    component: ProductPage,
    exact: true,
  } as AppRoute,
  {
    path: pageRoutes.cart,
    name: "Cart",
    component: ProductPage,
    exact: true,
  } as AppRoute,
];

export default indexRoutes;
