import { IconColor, Icon } from "../../ga-components/typography/icon";
import { IconSize } from "../../ga-components/typography/icon/icon-size";

type Props = {
  title: string;
  amount: number;
};

const BalanceChip: React.FC<Props> = ({ title, amount }) => {
  return (
    <div className="inline-flex rounded-md w-full bg-yellow-100 font-medium leading-5 text-slate-700 shadow-sm ring-1 ring-slate-700/10 ">
      <div className="flex py-2 px-3 font-light text-small items-center justify-center">
        {title}
      </div>
      <div className="text-2xl font-bold border-l border-slate-400/20 py-2 px-2.5">
        <Icon icon="wallet" color={IconColor.PRIMARY} size={IconSize.xl} />{" "}
        {amount ? amount : 0}
      </div>
    </div>
  );
};

export default BalanceChip;
