import React, { useState } from "react";
import withTheme from "../../providers/theme/withTheme";
import { IconColor } from "../typography/icon";
import Icon from "../typography/icon/icon";
import ButtonColor from "./button-color";
import ButtonType from "./button-type";

type Props = {
  theme: any;
  text: React.ReactNode;
  onClick: Function;
  color: ButtonColor;
  icon?: string;
  loading?: boolean;
  type?: ButtonType;
  disabled?: boolean;
  className?: string;
};
const Button: React.FC<Props> = ({
  theme,
  text,
  onClick,
  color = ButtonColor.PRIMARY,
  icon = undefined,
  loading = false,
  type = ButtonType.FILLED,
  disabled = false,
  className = "",
}) => {
  const cssClass = [
    disabled ? theme.button.colors["disabled"] : theme.button.colors[color],
    theme.button.base,
    theme.button[type],
    className,
  ].join(" ");

  const buttonNameCssClass = [
    theme.button.name,
    !icon && !loading ? "pl-0" : "",
  ].join(" ");

  return (
    <button
      className={cssClass}
      onClick={() => onClick()}
      disabled={loading || disabled}
    >
      {loading && (
        <span className={theme.button.icon}>
          <Icon icon="loading" spin={true} color={IconColor.WHITE} />
        </span>
      )}
      {!loading && icon && (
        <span className={theme.button.icon}>
          <Icon icon={icon} color={IconColor.WHITE} />
        </span>
      )}
      <span className={buttonNameCssClass}>{text}</span>
    </button>
  );
};

export default withTheme(Button);
