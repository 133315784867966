import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Address } from "../../../domain/models/address";
import { Result } from "../../../domain/models/result";
import { GetAddressList } from "../../../domain/usages/get-address-list";
import { Button, ButtonColor, ButtonType } from "../../ga-components/buttons";
import {
  Card,
  CardBody,
  CardColor,
  CardRightContent,
  CardSupportingText,
} from "../../ga-components/cards";
import Clickable from "../../ga-components/clickable";
import { InputVariant } from "../../ga-components/inputs";
import { Icon, IconColor } from "../../ga-components/typography/icon";
import { IconSize } from "../../ga-components/typography/icon/icon-size";
import { CartState } from "../../providers/cart/CartContext";
import { CartAction } from "../../providers/cart/CartProvider";
import withCart from "../../providers/cart/withCart";
import withTheme from "../../providers/theme/withTheme";
import Ajv from "ajv";
const ajv = new Ajv();

const schema = {
  type: "object",
  properties: {
    type: { type: "string", minimum: 3 },
    pin_code: { type: "string", pattern: "^[1-9]{1}[0-9]{5}$" },
    line: {
      type: "string",
      minLength: 10,
      maxLength: 40,
      pattern: "^[-0-9A-Za-z.&,/ ]+$",
    },
    landmark: {
      type: "string",
      minLength: 10,
      maxLength: 40,
      pattern: "^[-0-9A-Za-z.&,/ ]+$",
    },
    area: {
      type: "string",
      minLength: 3,
      maxLength: 40,
      pattern: "^[-0-9A-Za-z.&,/ ]+$",
    },
    district: {
      type: "string",
      minLength: 3,
      maxLength: 40,
      pattern: "^[-0-9A-Za-z.& ]+$",
    },
    state: {
      type: "string",
      minLength: 3,
      maxLength: 40,
      pattern: "^[-0-9A-Za-z.& ]+$",
    },
    country: {
      type: "string",
      minLength: 3,
      maxLength: 40,
      pattern: "^[-0-9A-Za-z.& ]+$",
    },
  },
  required: [
    "type",
    "pin_code",
    "line",
    "landmark",
    "area",
    "district",
    "state",
    "country",
  ],
  additionalProperties: false,
};

type Props = {
  theme: any;
  cart: CartState;
  variant: InputVariant;
  cartOperationEventEmitter: Function;
  getAddressList: GetAddressList;
  onValidationUpdated: Function;
};

const AddressSelection: React.FC<Props> = ({
  theme,
  cart,
  variant,
  getAddressList,
  cartOperationEventEmitter,
  onValidationUpdated,
}) => {
  const navigate = useNavigate();
  const {
    type,
    cart: { address },
  } = cart;
  const [loading, setLoading] = useState<boolean>();
  const [isValid, setIsValid] = useState<boolean>();
  const [formattedAddress, setFormattedAddress] = useState<String>("");

  /** Fetch addresses from server */
  const fetchAddresses = useCallback(async () => {
    setLoading(true);
    let result: Result = await getAddressList.get({});
    if (result.addresses) {
      if (result.addresses.length > 0) {
        validate(result.addresses[result.addresses.length - 1]);
        cartOperationEventEmitter({
          type: CartAction.SET_ADDRESS,
          payload: { address: result.addresses[result.addresses.length - 1] },
        });
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [getAddressList]);

  const validate = async (address: Address) => {
    console.log(address);
    const ajvValidate = ajv.compile(schema);
    const valid = ajvValidate({
      type: address.type,
      pin_code: address.pin_code,
      line: address.line,
      landmark: address.landmark,
      area: address.area,
      district: address.district,
      state: address.state,
      country: address.country,
    });
    onValidationUpdated(valid);
    console.log(valid);
    if (!valid) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  };

  useEffect(() => {
    if (!loading) fetchAddresses();
  }, []);

  useEffect(() => {
    setFormattedAddress(
      `${address.line}, ${address.landmark},
    ${address.area}, ${address.district}, ${address.state}, 
    ${address.country} - ${address.pin_code}`
        .replace(/(,)+/gi, ",")
        .replace(/(,)+/gi, ", ")
    );
  }, [address]);

  const addNewAddress = () => {
    cartOperationEventEmitter({
      type: CartAction.SET_ADDRESS,
      payload: { address: {} as Address },
    });
    navigate("/address");
  };

  const editAddress = () => {
    navigate("/address");
  };

  if (loading)
    return (
      <Card color={CardColor.WHITE}>
        <CardBody className="text-left pb-0">
          <CardSupportingText className="font-semibold">
            Delivery Address {loading && "(Preparing...)"}
          </CardSupportingText>
        </CardBody>
      </Card>
    );
  return (
    <Card color={CardColor.WHITE}>
      <CardBody className="text-left pb-0">
        <CardSupportingText className="font-semibold">
          Delivery Address{" "}
          {!isValid && <span className="text-red-500">(Invalid Address)</span>}
        </CardSupportingText>
      </CardBody>
      <CardBody className="pt-4">
        <div className="flex items-center">
          <div className="basis-1/12">
            <div className={`${theme.input[variant].addressField.icon}`}>
              <Icon
                icon="location"
                size={IconSize.sm}
                color={IconColor.WHITE}
              />
            </div>
          </div>
          <CardSupportingText className="text-left p-2 text-sm basis-10/12">
            <div className="">{formattedAddress}</div>
            {/* <div className=""></div> */}
          </CardSupportingText>
          <CardSupportingText className="text-right p-2 text-sm basis-1/12">
            <Clickable onClick={editAddress}>
              <div className={`${theme.input[variant].addressField.edit}`}>
                EDIT
              </div>
            </Clickable>
          </CardSupportingText>
        </div>
      </CardBody>
      <CardBody className="pt-0">
        <Button
          className={`${theme.input[variant].addressField.add}`}
          type={ButtonType.OUTLINED}
          color={
            variant == InputVariant.FILLED
              ? ButtonColor.WHITE
              : ButtonColor.SECONDARY_WHITE
          }
          text={
            <div>
              <Icon icon="add" /> Add new Address
            </div>
          }
          onClick={addNewAddress}
        />
      </CardBody>
    </Card>
  );
};

export default withCart(withTheme(AddressSelection));
