import moment from "moment";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { AUTH_DOCTOR, AUTH_USER } from "../../../base";
import { JsonStorage } from "../../../data/protocols/storage/json-storage";
import { Address } from "../../../domain/models/address";
import { Order } from "../../../domain/models/order";
import { OrderItem } from "../../../domain/models/order-item";
import { Product } from "../../../domain/models/product";
import { OrderTokenItem } from "../../../domain/models/product-token-item";
import { Result } from "../../../domain/models/result";
import { Token } from "../../../domain/models/token";
import { CreateOrder } from "../../../domain/usages/create-order";
import { GenerateOrderToken } from "../../../domain/usages/generate-order-token";
import { pageRoutes } from "../../../routes";
import {
  Card,
  CardBody,
  CardColor,
  CardSubHeadline,
  CardSupportingText,
} from "../../ga-components/cards";
import Clickable from "../../ga-components/clickable";
import { Icon, IconColor } from "../../ga-components/typography/icon";
import { IconSize } from "../../ga-components/typography/icon/icon-size";
import { CartState } from "../../providers/cart/CartContext";
import withCart from "../../providers/cart/withCart";

type Props = {
  token?: string;
  cart: CartState;
  disabled?: boolean;
  cartOperationEventEmitter: Function;
  createOrder: CreateOrder;
  storage: JsonStorage;
  generateOrderToken: GenerateOrderToken;
};

const PlaceOrderButton: React.FC<Props> = ({
  token,
  cart,
  disabled = false,
  cartOperationEventEmitter,
  createOrder,
  storage,
  generateOrderToken,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const {
    type,
    cart: { products, address },
    card_id,
  } = cart;

  const onClick = async () => {
    if (!card_id) return;

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton:
          "bg-primary rounded-lg pr-6 pl-4 h-12 text-sm text-white",
        cancelButton:
          "bg-red-500 rounded-lg pr-6 pl-4 h-12 text-sm text-white ml-2",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Disclaimer!",
        html: "<p style='text-align: justify'>I declare that am a registered medical practitioner and I am licensed to prescribe the drugs that I have requested Cipla Limited to supply the samples. The said samples will be used exclusively for free distribution to my patients. I shall store the drugs under my custody and freely distribute the same under by personal supervision. I irrevocably undertake to indemnify and keep Cipla Limited, its directors, officers, employees and its employees indemnified from all costs, damages and losses that it may suffer as a result misuse of the said Drug.</p>",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Yes Confirm!",
        cancelButtonColor: "#d33",
      })
      .then(async (result) => {
        if (result.value) {
          setLoading(true);
          const tokens = await generateToken();
          if (tokens) placeOrder(prepareOrder(card_id, tokens));
        }
      });
  };

  const prepareOrder = (card_id: string, tokens: Token[]) => {
    const user: any = JSON.parse(storage.get(AUTH_USER) ?? JSON.stringify({}));
    const doctor: any = JSON.parse(
      storage.get(AUTH_DOCTOR) ?? JSON.stringify({})
    );
    let order: Order = {
      order_date: moment().format("YYYY-MM-DD"),
      card_id: card_id,
      receiver: user.full_name,
      receiver_contact: user.mobile,
      receiver_msl_code: doctor.msl_code,
      type: "sample",
      delivery_address: {
        type: address.type,
        pin_code: address.pin_code,
        line: address.line,
        landmark: address.landmark,
        area: address.area,
        district: address.district,
        state: address.state,
        country: address.country,
      } as Address,
      items: products
        .filter((p: Product) => {
          return p.price;
        })
        .map((p: Product) => {
          return {
            code: p.price?.uom.sku_code,
            order_token: tokens?.find((t) => t.product_id == p.id)?.order_token,
            item: p.name,
            description: p.description,
            quantity: p.quantity,
            unit_price: p.price?.value,
            uom: p.price?.uom.name,
            discount: 0,
            product_id: p.id,
            dept_code: p.dept_code,
            org_code: p.org_code,
          } as OrderItem;
        }),
    };

    return order;
  };

  const generateToken = useCallback(async () => {
    const result = await generateOrderToken.generate({
      product: products
        .filter((p: Product) => {
          return p.price;
        })
        .map((p: Product) => {
          return {
            code: p.price?.uom.sku_code,
            id: p.id,
            dept_code: p.dept_code,
          } as OrderTokenItem;
        }),
    });
    if (result.tokens) {
      return result.tokens;
    }
    return false;
  }, []);

  const placeOrder = useCallback(
    async (order: Order) => {
      let result: Result = await createOrder.create(order, token);
      if (result.success) {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "You Sample request is placed successfully.",
          showConfirmButton: false,
          timer: 1500,
        });
        goToOrders();
      } else if (result.errors) {
        Swal.fire({
          icon: "error",
          title: result.errors.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    [token]
  );

  const goToOrders = () => {
    navigate(pageRoutes.orders);
  };

  return (
    <Clickable onClick={onClick} disabled={disabled || loading}>
      <Card
        color={disabled ? CardColor.DISABLED : CardColor.PRIMARY}
        className="rounded-none"
      >
        <CardBody className="p-2">
          <div className="flex justify-center m-auto">
            <div className="m-auto">
              {loading && (
                <span className="w-4 ">
                  <Icon
                    icon="loading"
                    spin={true}
                    color={IconColor.WHITE}
                    size={IconSize.xl}
                  />
                </span>
              )}
            </div>
            <div className="grow items-center text-center">
              <CardSubHeadline className="text-white">
                Confirm & Place Order
              </CardSubHeadline>
              <CardSupportingText className="text-white">
                Click here to place order
              </CardSupportingText>
            </div>
          </div>
        </CardBody>
      </Card>
    </Clickable>
  );
};

export default withCart(PlaceOrderButton);
