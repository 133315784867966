import { ORDER_AUTH_TOKEN } from "../../base";
import { EmptyResponse, Result } from "../../domain/models/result";
import { CreateOrder } from "../../domain/usages/create-order";
import { HttpPostClient } from "../protocols/http/http-post-client";

export class RemoteCreateOrder implements CreateOrder {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}

  async create(
    params: CreateOrder.Params,
    identityToken?: string
  ): Promise<Result> {
    let headers: any = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    if (identityToken) {
      headers[ORDER_AUTH_TOKEN] = identityToken;
    }

    const httpResponse = await this.httpPostClient.post({
      url: this.url,
      body: params,
      headers: headers,
      authHeaders: true,
    });

    if (!httpResponse) {
      let response = EmptyResponse;
      response.errors = { message: "Server error. Please try after sometime." };
      return response;
    }
    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
