enum ButtonColor {
  PRIMARY = "primary",
  NEW_PRIMARY = "newPrimary",
  PRIMARY_LOW = "primaryMedium",
  SECONDARY = "secondary",
  SECONDARY_LOW = "secondaryMedium",
  SECONDARY_WHITE = "secondaryWhite",
  DANGER = "danger",
  WHITE = "white",
}

export default ButtonColor;
