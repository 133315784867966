import React, { useCallback, useEffect, useState } from "react";
import {
  LOWER_CAPPING,
  PENDING,
  UPPER_CAPPING,
  VERIFIED,
  WARNING_CAPPING,
} from "../../../Constants";
import { AddPanCard } from "../../../domain/usages/add-pan-card";
import { GetCurrentUsage } from "../../../domain/usages/get-current-usage";
import { GetIdentifyToken } from "../../../domain/usages/get-identify-token";
import { GetPanCardDetails } from "../../../domain/usages/get-pan-card-details";
import PanCardForm from "./pan-card-form";
import PanCardProcessing from "./pan-card-processing";
import PanCardWarning from "./pan-card-warning";
import withCart from "../../providers/cart/withCart";
import { CartState } from "../../providers/cart/CartContext";
import { CartModel } from "../../providers/cart/models/cart-model";

type Props = {
  getPanCardDetails: GetPanCardDetails;
  getIdentifyToken: GetIdentifyToken;
  addPanCard: AddPanCard;
  getCurrentUsage: GetCurrentUsage;
  setToken: (token: string) => void;
  cart: CartState;
  cartOperationEventEmitter: Function;
};

const PanCardCheck: React.FC<Props> = ({
  getPanCardDetails,
  getIdentifyToken,
  addPanCard,
  getCurrentUsage,
  setToken,
  cart,
  cartOperationEventEmitter,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [verificatiionStatus, setVerificationStatus] = useState<String>();
  const [mandatory, setMandatory] = useState<boolean>(false);
  const [close, setClose] = useState<boolean>(false);
  const [showWarning, setShowWarning] = useState<boolean>(false);

  const fetchCurrentUsage = useCallback(async () => {
    setLoading(true);
    // TODO:: remove this CIPLA hard coding.
    const cartValue = calculateCartValue(cart.cart);
    const response = await getCurrentUsage.get({ entity: "CIPLA" });
    if (response.success) {
      const utilizedValue = cartValue + parseFloat(response.utilized_limit);
      if (utilizedValue >= UPPER_CAPPING) {
        setMandatory(true);
      } else if (utilizedValue < LOWER_CAPPING) {
        if (utilizedValue >= WARNING_CAPPING) {
          setShowWarning(true);
        } else {
          setClose(true);
        }
      }
      setLoading(false);
    }
  }, [getCurrentUsage]);

  const fetchPanCardDetails = useCallback(async () => {
    const response = await getPanCardDetails.get({});
    if (response.success) {
      setVerificationStatus(response.verification_status);
      if (response.verification_status === VERIFIED) {
        fetchIdentifyToken();
      }
    }
  }, [getPanCardDetails]);

  useEffect(() => {
    fetchCurrentUsage();
    fetchPanCardDetails();
  }, ["getPanCardDetails", "getCurrentUsage"]);

  const fetchIdentifyToken = useCallback(async () => {
    const response = await getIdentifyToken.get({});
    if (response.success) {
      setToken(response.token);
    }
  }, [getIdentifyToken]);

  const calculateCartValue = (cart: CartModel) => {
    return cart.products.reduce((total: number, product: any) => {
      return (
        total +
        parseFloat(product.quantity) * parseFloat(product.prices[0].value)
      );
    }, 0);
  };

  if (showWarning) {
    return (
      <div className="relative z-10" role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <PanCardWarning
              close={() => {
                setShowWarning(false);
                setClose(true);
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  if (close || loading) return <></>;

  if (verificatiionStatus == VERIFIED) {
    return <></>;
  }

  return (
    <div className="relative z-10" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          {verificatiionStatus == PENDING && (
            <PanCardProcessing
              skip={() => setClose(true)}
              mandatory={mandatory}
            />
          )}
          {!verificatiionStatus && (
            <PanCardForm
              addPanCard={addPanCard}
              mandatory={mandatory}
              skip={() => setClose(true)}
              panUpdated={() => fetchPanCardDetails()}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default withCart(PanCardCheck);
