import { useCallback, useEffect, useState } from "react";
import { WalletTransaction } from "../../../domain/models/wallet-transaction";
import { GetWalletTransactionList } from "../../../domain/usages/get-wallet-transaction-list";
import WalletTransactionCard from "./wallet-transaction-card";

type Props = {
  walletToken: string;
  getWalletTransactionList: GetWalletTransactionList;
  refresh: boolean;
};
const WalletTransactionList: React.FC<Props> = ({
  walletToken,
  getWalletTransactionList,
  refresh,
}) => {
  const [transactions, setTransactions] = useState<WalletTransaction[]>();
  const [page, setPage] = useState(1);

  /**
   * Get list of transactions
   */
  const getTransactions = useCallback(async () => {
    const result = await getWalletTransactionList.get(walletToken, {
      page: page,
      length: 20,
    });
    if (result.success) {
      setTransactions(result.account_transactions);
    }
  }, [getWalletTransactionList, refresh]);

  /**
   * On start of component fetch the wallet transactions
   */
  useEffect(() => {
    getTransactions();
  }, [getWalletTransactionList, refresh]);

  if (!transactions) {
    return <div>Loading...</div>;
  }

  if (transactions.length === 0) {
    return <div>No transactions found</div>;
  }
  return (
    <div className="overflow-y">
      {transactions.map((transaction) => {
        return <WalletTransactionCard transaction={transaction} />;
      })}
    </div>
  );
};

export default WalletTransactionList;
