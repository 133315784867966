import { useCallback } from "react";
import { COD_WALLET_ORG_ID, COD_WALLET_VENDOT_ID } from "../../../Constants";
import { Result } from "../../../domain/models/result";
import { CreateRewardRequest } from "../../../domain/usages/create-reward-request";
import { Button, ButtonColor } from "../../ga-components/buttons";
import Swal from "sweetalert2";

type Props = {
  title: string;
  totalAmount: number;
  createRewardRequest: CreateRewardRequest;
  onSuccess?: () => void;
};

const ConvertButton: React.FC<Props> = ({
  title,
  totalAmount,
  createRewardRequest,
  onSuccess,
}) => {
  const convert = useCallback(
    async (amount: number) => {
      const result: Result = await createRewardRequest.create({
        amount: amount,
        org_id: COD_WALLET_ORG_ID,
        vendor_id: COD_WALLET_VENDOT_ID,
      });
      if (result.success) {
        Swal.fire({
          icon: "success",
          title: "Your request has been submitted.",
        });
        if (onSuccess) onSuccess();
      } else {
        Swal.showValidationMessage(`Request failed: ${result.errors.amount}`);
      }
    },
    [createRewardRequest]
  );

  const submit = () => {
    Swal.fire({
      title: "Please enter the amount you want to convert?",
      input: "text",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes Confirm!",
      cancelButtonColor: "#d33",
      preConfirm: async (amount) => {
        if (amount <= totalAmount) {
          return convert(amount);
        }
        Swal.showValidationMessage(
          `Request failed: Cannot redeem more than ${totalAmount}`
        );
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
      }
    });
  };

  return (
    <>
      <Button
        disabled={totalAmount <= 0}
        text={"Convert"}
        color={ButtonColor.PRIMARY}
        onClick={() => submit()}
        className={"w-full h-full"}
      />
    </>
  );
};

export default ConvertButton;
