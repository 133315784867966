import { Result } from "../../domain/models/result";
import { GetUserGroupToken } from "../../domain/usages/get-user-group-token";
import { HttpGetClient } from "../protocols/http/http-get-client";

export class RemoteGetUserGroupToken implements GetUserGroupToken {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async get(params: GetUserGroupToken.Params): Promise<Result> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url,
      query: params,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      authHeaders: true,
    });

    return httpResponse.data;
  }
}
