const theme = {
  typography: {
    display: {
      large: "text-6xl",
      medium: "text-5xl",
      small: "text-4xl",
    },
    headline: {
      large: "text-4xl",
      medium: "text-3xl",
      small: "text-2xl",
    },
    title: {
      large: "text-lg",
      medium: "text-base",
      small: "text-sm",
    },
    label: {
      large: "text-base",
      medium: "text-sm",
      small: "text-xs",
    },
    body: {
      large: "text-base",
      medium: "text-sm",
      small: "text-xs",
    },
  },
  navBar: {
    base: "z-20 top-0 flex fixed items-center w-full overflow-hidden justify-start h-14 lg:h-16",
    left: "w-14",
    headline: "text-base",
    subHeadline: "text-xs text-gray-500",
    colors: {
      primary: "bg-primary text-white",
      new_primary: "bg-new-primary text-white",
      white: "text-primary bg-white",
      secondaryWhite: "text-secondary bg-white",
    },
  },
  button: {
    base: "rounded-lg pr-6 pl-4 h-10 text-xs",
    name: "pl-2",
    icon: "w-4",
    elevated: "drop-shadow",
    filled: "",
    tonal: "",
    outlined: "border",
    text: "",
    colors: {
      primary: "bg-primary text-white",
      newPrimary: "bg-new_primary text-white",
      primaryMedium: "bg-primary_medium",
      primaryLow: "bg-primary_low text-primary",
      secondary: "bg-secondary text-white",
      secondaryMedium: "bg-secondary_medium",
      secondaryLow: "bg-secondary_low text-secondary",
      white: "bg-white text-primary",
      secondaryWhite: "bg-white text-secondary",
      disabled: "bg-gray-100 text-gray-500",
      danger: "text-red-500",
    },
  },
  chip: {
    base: "px-2 py-1 h-8 rounded-lg tracking-wide font-medium text-xs",
    suggestion: "border border-gray-400",
    filter: "",
    text: "px-2 leading-5",
    colors: {
      primary: "bg-primary text-white",
      primaryMedium: "bg-primary_medium",
      primaryLow: "bg-primary_low text-primary",
      secondary: "bg-secondary text-white",
      secondaryMedium: "bg-secondary_medium",
      secondaryLow: "bg-secondary_low text-secondary",
      white: "bg-white text-primary",
      gray: "bg-gray-200",
      danger: "bg-red-200",
    },
  },
  card: {
    base: "rounded-lg overflow-hidden relative",
    body: "p-4",
    headline: "text-4xl",
    subHeadline: "text-base",
    supportingText: "text-xs text-gray-500 font-light min-h-8",
    cardRightContent: "absolute top-0 right-0 mt-2 mr-2 p-2",
    actions: "py-4 w-full",
    tags: "p-4 w-full",
    image: "bg-contain bg-no-repeat bg-center w-full ",
  },
  message: {
    warning: {
      bg: "bg-secondary_low px-2 py-2 rounded-none text-sm",
      text: "text-secondary",
    },
    error: {
      bg: "bg-red-50 px-2 py-2 rounded-none text-sm",
      text: "text-red-900",
    },
    info: {
      bg: "bg-primary_low px-2 py-2 rounded-none text-sm",
      text: "text-primary",
    },
  },
  size: {
    xs: "text-xs",
    sm: "text-sm",
    base: "text-base",
    lg: "text-lg",
    xl: "text-xl",
    xl2: "text-2xl",
  },
  text: {
    primary: "text-primary",
    primaryMedium: "text-primary_medium",
    primaryLow: "text-primary_low",
    secondary: "text-secondary",
    secondaryMedium: "text-secondary_medium",
    secondaryLow: "text-secondary_low",
    white: "text-white",
  },
  bg: {
    primary: "bg-primary",
    primaryMedium: "bg-primary_medium",
    primaryLow: "bg-primary_low",
    secondary: "bg-secondary",
    secondaryMedium: "bg-secondary_medium",
    secondaryLow: "bg-secondary_low",
    white: "bg-white",
    gray: "bg-gray-200",
  },
  input: {
    primary: {
      field:
        "bg-gray-50 border border-primary_medium pl-4 pr-3 h-12 w-full rounded-lg focus:outline-none text-black",
      select:
        "appearance-none bg-white h-12 w-full h-full rounded-md focus:outline-none focus:ring-2 focus:ring-primary_medium",
      errorField:
        "border border-primary_medium border border-red-300 pl-4 pr-3 h-12 w-full rounded-md focus:outline-none focus:ring-1 focus:ring-red-400",
      label: "text-xs font-400 text-primary",
      error: "text-xs text-red-900",
      helpText: "text-xs text-gray-400",
      search: {
        base: "flex justify-center shadow-sm bg-white rounded-lg",
        left: "flex w-12 h-12 items-center justify-center",
        field: "pl-4 pr-3 h-12 w-full rounded-lg focus:outline-none text-black",
      },
      addressField: {
        base: "",
        icon: "bg-primary rounded-full align-middle h-8 w-8 px-2 py-1 text-center",
        edit: "text-primary",
        add: "w-full border-1 border-primary h-8",
      },
    },
    newPrimary: {
      field:
        "bg-gray-50 border border-new_primary_medium pl-4 pr-3 h-12 w-full rounded-lg focus:outline-none text-black",
      select:
        "appearance-none bg-white h-12 w-full h-full rounded-md focus:outline-none focus:ring-2 focus:ring-new_primary_medium",
      errorField:
        "border border-new_primary_medium border border-red-300 pl-4 pr-3 h-12 w-full rounded-md focus:outline-none focus:ring-1 focus:ring-red-400",
      label: "text-xs font-400 text-new_primary",
      error: "text-xs text-red-900",
      helpText: "text-xs text-gray-400",
    },
    secondary: {
      field:
        "bg-gray-50 border border-secondary_medium pl-4 pr-3 h-12 w-full rounded-lg focus:outline-none text-black",
      label: "text-xs font-400 text-secondary",
      error: "text-xs text-red-900",
      helpText: "text-xs text-gray-400",
      buttonSelect: {
        base: "list-none flex justify-center p-2 text-base",
        option: "px-4 py-2 text-center border ml-2",
        error: "text-xs text-red-900",
        active: "bg-secondary text-white",
        pending: "bg-white",
      },
      addressField: {
        base: "",
        icon: "bg-secondary rounded-full align-middle h-8 w-8 px-2 py-1 text-center",
        edit: "text-secondary",
        add: "w-full border-1 border-secondary h-8",
      },
    },
    error: "text-xs text-red-900",
  },
  tab: {
    base: "bg-primary_low rounded-full items-center flex-nowrap flex h-12 lg:h-16 overflow-auto px-4",
    item: {
      base: "cursor-pointer py-2 px-4 text-xs grow",
      active: "bg-primary rounded-full text-white",
      inActive: "",
    },
  },
};

export default theme;
