import {
  AUTH_TOKEN_HEADER,
  AUTH_TOKEN_KEY,
  BASE_DOCTOR_API_ENDPOINT,
  BASE_ORDER_API_ENDPOINT,
} from "../../base";
import { RemoteCreateOrder } from "../../data/usercases/remote-create-order";
import { RemoteGenerateOrderToken } from "../../data/usercases/remote-generate-order-token";
import { RemoteGetProductList } from "../../data/usercases/remote-get-product-list";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import CartPage from "../../presentation/screens/order/CartPage";

type Props = {};

export const CartPageFactory = (props: Props) => {
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN_KEY);

  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({
    [AUTH_TOKEN_HEADER]: token,
  });

  const remoteGetAddressList = new RemoteGetProductList(
    `${BASE_DOCTOR_API_ENDPOINT}/doctors/delivery-addresses`,
    axiosHttpClient
  );

  const remoteCreateOrder = new RemoteCreateOrder(
    `${BASE_ORDER_API_ENDPOINT}/orders`,
    axiosHttpClient
  );

  const generateOrderToken = new RemoteGenerateOrderToken(
    `${BASE_ORDER_API_ENDPOINT}/orders/generateOrderToken`,
    axiosHttpClient
  );

  return (
    <CartPage
      generateOrderToken={generateOrderToken}
      getAddressList={remoteGetAddressList}
      createOrder={remoteCreateOrder}
      storage={storage}
      {...props}
    />
  );
};
