import {
  AUTH_TOKEN_HEADER,
  AUTH_TOKEN_KEY,
  BASE_ORDER_API_ENDPOINT,
} from "../../base";
import { RemoteCancelSubscription } from "../../data/usercases/remote-cancel-subscription";
import { RemoteGetSubscriptionList } from "../../data/usercases/remote-get-subscription-list";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import SubscriptionListPage from "../../presentation/screens/order/SubscriptionListPage";

type Props = {};

export const SubscriptionListPageFactory = (props: Props) => {
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN_KEY);

  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({
    [AUTH_TOKEN_HEADER]: token,
  });

  const getSubscriptionList = new RemoteGetSubscriptionList(
    `${BASE_ORDER_API_ENDPOINT}/subscriptions`,
    axiosHttpClient
  );

  const cancelSubscription = new RemoteCancelSubscription(
    `${BASE_ORDER_API_ENDPOINT}/subscriptions/:id/cancel`,
    axiosHttpClient
  );

  return (
    <SubscriptionListPage
      getSubscriptionList={getSubscriptionList}
      cancelSubscription={cancelSubscription}
      {...props}
    />
  );
};
