import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AUTH_USER } from "../../../base";
import { ACTIVE, CATEGORY_LIST } from "../../../Constants";
import { JsonStorage } from "../../../data/protocols/storage/json-storage";
import { Product, ProductFilter } from "../../../domain/models/product";
import { Result } from "../../../domain/models/result";
import { GetOrderedSkuCodes } from "../../../domain/usages/get-ordered-sku-codes";
import { GetProductList } from "../../../domain/usages/get-product-list";
import { GetSubscribedSkuCodes } from "../../../domain/usages/get-subscribed-sku-codes";
import NavigationModal from "../../components/common/navigation-modal";
import GoToCartButton from "../../components/products/go-to-cart-button";
import ProductCard from "../../components/products/product-card";
import { Button, ButtonColor, ButtonType } from "../../ga-components/buttons";
import { SearchField } from "../../ga-components/inputs";
import { NavBar, NavColor } from "../../ga-components/nav";
import { Tab, TabModel, Tabs } from "../../ga-components/tabs";
import { CartState } from "../../providers/cart/CartContext";
import { CartAction } from "../../providers/cart/CartProvider";
import withCart from "../../providers/cart/withCart";

const FilterTabs = CATEGORY_LIST.map(
  (c) => ({ id: c.value, text: c.label, value: c.value } as TabModel)
);

type Props = {
  getProductList: GetProductList;
  getOrderedProductCodes: GetOrderedSkuCodes;
  getSubscribedProductCodes: GetSubscribedSkuCodes;
  storage: JsonStorage;
  cart: CartState;
  cartOperationEventEmitter: Function;
};
const ProductPage: React.FC<Props> = ({
  getProductList,
  getOrderedProductCodes,
  getSubscribedProductCodes,
  storage,
  cart,
  cartOperationEventEmitter,
}) => {
  const [querySearch] = useSearchParams();
  const [filters, setFilters] = useState<ProductFilter>({
    org_code: querySearch.get("org_code") ?? undefined,
    display_category: querySearch.get("display_category") ?? undefined,
    category: "gift",
  });
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);

  /**
   * Go to previous page
   * If coming from mobile app go back to app
   * If coming from web go to previous page from history
   */
  let goBack = () => {
    let BackButton = (window as { [key: string]: any })["BackButton"];
    if (BackButton) BackButton.postMessage("Hello!");
    else {
      window.open("about:blank", "_self");
      window.close();
    }
  };

  /** Fetch current months subscription from server */
  const fetchSubscriptions = useCallback(async () => {
    let result: Result = await getSubscribedProductCodes.get({
      status: ACTIVE,
    });
    if (result.sku_codes) {
      return result.sku_codes;
    }
    return false;
  }, [getSubscribedProductCodes]);

  /** Fetch current months orders from server */
  const fetchOrders = useCallback(
    async (date: string) => {
      let result: Result = await getOrderedProductCodes.get({
        monthYear: moment().format("YYYYMM"),
      });
      if (result.sku_codes) {
        return result.sku_codes;
      }
      return false;
    },
    [getOrderedProductCodes]
  );

  /** Fetch products from server */
  const fetchProducts = useCallback(async () => {
    const queryFilters = { ...filters };
    queryFilters.org_code =
      queryFilters.org_code == "ALL" ? undefined : queryFilters.org_code;
    let result: Result = await getProductList.get(queryFilters);
    if (result.products) {
      setProducts(result.products);
      return true;
    }
    return false;
  }, [getProductList, filters.q]);

  useEffect(() => {
    const user: any = JSON.parse(storage.get(AUTH_USER) ?? JSON.stringify({}));
    cartOperationEventEmitter({
      type: CartAction.SET_CARD,
      payload: { card_id: querySearch.get("card_id"), user: user },
    });
    setLoading(true);
    Promise.all([
      fetchProducts(),
      fetchOrders(moment().format("YYYY-MM")),
      fetchSubscriptions(),
    ]).then((results) => {
      if (results.every((r) => r)) {
        setLoading(false);
        cartOperationEventEmitter({
          type: CartAction.SET_ORDERED_IDS,
          payload: {
            orderedSkuCodes: results[1],
            subscribedSkuCodes: results[2],
          },
        });
      }
      setLoading(false);
    });
  }, []);

  const onTabSelected = (tab: string) => {
    filters.org_code = tab;
    setFilters(filters);
    fetchProducts();
  };

  const debouncedSearch = debounce((q) => {
    filters.q = q;
    setFilters(filters);
    fetchProducts();
  }, 700);

  return (
    <>
      <NavBar
        title={"Products"}
        subTitle={"Select products to place sample request"}
        color={NavColor.WHITE}
        back={true}
        onBackClick={goBack}
        right={<NavigationModal />}
      />
      <div className="p-2 h-full">
        <SearchField
          placeholder="Search Product..."
          value={filters.q ?? ""}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            debouncedSearch(e.target.value);
          }}
        />
        <div className="pt-2"></div>
        <Tabs>
          {FilterTabs.map((t) => (
            <Tab
              item={t}
              active={filters && filters.org_code == t.id}
              onClick={() => {
                onTabSelected(t.id);
              }}
              key={t.id}
            />
          ))}
        </Tabs>
        <div className="pt-2"></div>
        {loading && (
          <div className="flex flex-col">
            <Button
              type={ButtonType.FILLED}
              color={ButtonColor.PRIMARY}
              text="Preparing products..."
              loading={true}
              onClick={() => {}}
            />
          </div>
        )}
        <div className="h-4/5 overflow-auto ">
          <div className="container grid grid-cols-2 lg:grid-cols-6 md:grid-cols-3 gap-2 mb-16 mx-auto">
            {!loading &&
              products &&
              products.length > 0 &&
              products.map((p) => {
                return (
                  <div className="" key={p.id}>
                    <ProductCard product={p} />
                  </div>
                );
              })}
          </div>
        </div>
        <div className="fixed bottom-0 w-full left-0">
          <GoToCartButton />
        </div>
      </div>
    </>
  );
};

export default withCart(ProductPage);
