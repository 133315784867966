import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { JsonStorage } from "../../../data/protocols/storage/json-storage";
import { Card } from "../../../domain/models/card";
import { Result } from "../../../domain/models/result";
import { GetCardList } from "../../../domain/usages/get-card-list";
import SingleCard from "../../components/cards/SingleCard";
import NavigationModal from "../../components/common/navigation-modal";
import GoToCartButton from "../../components/products/go-to-cart-button";
import { Button, ButtonColor, ButtonType } from "../../ga-components/buttons";
import { NavBar, NavColor } from "../../ga-components/nav";

type Props = {
  getCardList: GetCardList;
  storage: JsonStorage;
};
const CardListPage: React.FC<Props> = ({ getCardList, storage }) => {
  const [querySearch] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState<Card[]>([]);

  /**
   * Go to previous page
   * If coming from mobile app go back to app
   * If coming from web go to previous page from history
   */
  let goBack = () => {
    let BackButton = (window as { [key: string]: any })["BackButton"];
    if (BackButton) BackButton.postMessage("Hello!");
    else {
      window.open("about:blank", "_self");
      window.close();
    }
  };

  /** Fetch cards from server */
  const fetchCards = useCallback(async () => {
    const queryFilters = {};
    let result: Result = await getCardList.get(queryFilters);
    if (result.cards) {
      setCards(result.cards);
      return true;
    }
    return false;
  }, [getCardList]);

  useEffect(() => {
    fetchCards();
  }, []);

  return (
    <>
      <NavBar
        title={"CARDS"}
        subTitle={
          "Check services and products offered by different organizations"
        }
        color={NavColor.WHITE}
        back={true}
        onBackClick={goBack}
      />
      <div className="p-2 h-full">
        {/* <SearchField
          placeholder="Search Card..."
          value={filters.q ?? ""}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            debouncedSearch(e.target.value);
          }}
        /> */}
        <div className="pt-2"></div>
        {loading && (
          <div className="flex flex-col">
            <Button
              type={ButtonType.FILLED}
              color={ButtonColor.PRIMARY}
              text="Preparing cards..."
              loading={true}
              onClick={() => {}}
            />
          </div>
        )}
        <div className="h-full pb-10 overflow-auto">
          <div className="container grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 md:gap-5 mx-auto">
            {!loading &&
              cards &&
              cards.length > 0 &&
              cards.map((card: Card) => {
                return (
                  <div className="w-full" key={card._id}>
                    <SingleCard card={card} />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default CardListPage;
