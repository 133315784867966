import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AddPanCard } from "../../../domain/usages/add-pan-card";
import { Button, ButtonColor, ButtonType } from "../../ga-components/buttons";
import { InputField, InputVariant } from "../../ga-components/inputs";

type PanCardFormField = {
  pan_card: string;
};

type Props = {
  mandatory: boolean;
  addPanCard: AddPanCard;
  panUpdated: () => void;
  skip: () => void;
};

const PanCardForm: React.FC<Props> = ({
  mandatory,
  addPanCard,
  panUpdated,
  skip,
}) => {
  const [formLoading, setFormLoading] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<PanCardFormField>({
    mode: "all",
  });

  const submit = async (data: PanCardFormField) => {
    setFormLoading(true);
    const response = await addPanCard.add({ pan_number: data.pan_card });
    if (response.success) {
      panUpdated();
    }
    setFormLoading(false);
  };

  return (
    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3
              className="text-lg font-medium leading-6 text-gray-900"
              id="modal-title"
            >
              For better experience, please verify your PAN Card
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Under 194R of Income Tax Act, 1961, it is mandatory to verify
                your PAN Card. Please verify your PAN Card to continue ordering
                more samples on ConnectOD
              </p>
            </div>
            <div className="mt-2">
              <Controller
                name="pan_card"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputField
                    type="text"
                    value={value}
                    onChange={(e: any) => {
                      if (e.target && e.target.value) onChange(e.target.value);
                      else onChange("");
                    }}
                    variant={InputVariant.FILLED}
                    placeholder=""
                    error={error && error.message}
                    label="Pan Card Number"
                  />
                )}
                rules={{
                  required: { value: true, message: "Required" },
                  pattern: {
                    value: /([A-Z]){5}([0-9]){4}([A-Z]){1}$/,
                    message: "Invalid pan card number",
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="p-5">
        <div className="grid grid-rows-1 grid-flow-col gap-4">
          <div>
            {!mandatory && (
              <Button
                loading={formLoading}
                className="w-full"
                color={ButtonColor.WHITE}
                type={ButtonType.TEXT}
                text="SKIP"
                onClick={() => skip()}
              />
            )}
          </div>
          <div>
            <Button
              loading={formLoading}
              disabled={!isValid}
              className="w-full"
              color={ButtonColor.PRIMARY}
              type={ButtonType.FILLED}
              text="SUBMIT"
              onClick={handleSubmit(submit)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PanCardForm;
