import { Result } from "../../domain/models/result";
import { AddPanCard } from "../../domain/usages/add-pan-card";
import { HttpPostClient } from "../protocols/http/http-post-client";

export class RemoteAddPanCard implements AddPanCard {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}

  async add(data: AddPanCard.Params): Promise<Result> {
    const httpResponse = await this.httpPostClient.post({
      url: this.url,
      body: data,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      authHeaders: true,
    });

    return httpResponse.data;
  }
}
