import {
  AUTH_TOKEN_HEADER,
  AUTH_TOKEN_KEY,
  BASE_DOCTOR_API_ENDPOINT,
} from "../../base";
import { RemoteGetSurveyUrl } from "../../data/usercases/remote-get-survey-url";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import GoogleFormPage from "../../presentation/screens/survey/SurveyPage";

type Props = {};

export const SurveyPageFactory = (props: Props) => {
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN_KEY);

  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({
    [AUTH_TOKEN_HEADER]: token,
  });

  const getSurveyUrl = new RemoteGetSurveyUrl(
    `${BASE_DOCTOR_API_ENDPOINT}/doctors/survey`,
    axiosHttpClient
  );

  return (
    <GoogleFormPage
      storage={storage}
      getSurveyUrl={getSurveyUrl}
      {...props}
    />
  );
};
