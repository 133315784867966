import { Result } from "../../domain/models/result";
import { GetWalletBalance } from "../../domain/usages/get-wallet-balance";
import { HttpGetClient } from "../protocols/http/http-get-client";

export class RemoteGetWalletBalance implements GetWalletBalance {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async get(token: string, params: GetWalletBalance.Params): Promise<Result> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(":token", token),
      query: params,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      authHeaders: true,
    });

    return httpResponse.data;
  }
}
