import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CATEGORY_LIST } from "../../../Constants";
import { Result } from "../../../domain/models/result";
import { Subscription } from "../../../domain/models/subscription";
import { CancelSubscription } from "../../../domain/usages/cancel-subscription";
import { GetSubscriptionList } from "../../../domain/usages/get-subscription-list";
import NavigationModal from "../../components/common/navigation-modal";
import SubscriptionList from "../../components/subscriptions/subscription-list";
import { NavBar, NavColor } from "../../ga-components/nav";
import { TabModel } from "../../ga-components/tabs";

const FilterTabs = CATEGORY_LIST.map(
  (c) => ({ id: c.value, text: c.label, value: c.value } as TabModel)
);

type Props = {
  getSubscriptionList: GetSubscriptionList;
  cancelSubscription: CancelSubscription;
};
const SubscriptionListPage: React.FC<Props> = ({
  getSubscriptionList,
  cancelSubscription,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);

  /**
   * Go to previous page
   */
  let goBack = () => {
    navigate(-1);
  };

  /** Fetch subscriptions from server */
  const fetchSubscriptions = useCallback(async () => {
    setLoading(true);
    let result: Result = await getSubscriptionList.get({});
    if (result.subscriptions) {
      setSubscriptions(result.subscriptions);
    }
    setLoading(false);
  }, [getSubscriptionList]);

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  return (
    <div>
      <NavBar
        title={"Subscriptions"}
        subTitle={"See status of all your subscriptions here"}
        color={NavColor.WHITE}
        back={true}
        onBackClick={goBack}
        right={<NavigationModal />}
      />
      <div className="p-4 h-full overflow-auto">
        {subscriptions && subscriptions.length > 0 && (
          <SubscriptionList
            subscriptions={subscriptions}
            cancelSubscription={cancelSubscription}
            onCancel={() => fetchSubscriptions()}
          />
        )}
      </div>
    </div>
  );
};

export default SubscriptionListPage;
