import React, { useReducer } from "react";
import { Address } from "../../../domain/models/address";
import { Product } from "../../../domain/models/product";
import Cart from "./Cart";
import { CartState, CartType } from "./CartContext";

export enum CartAction {
  SET_ORDERED_IDS = "SET_ORDERED_IDS",
  SET_CARD = "SET_CARD",
  SET_ADDRESS = "SET_ADDRESS",
  ADD_PRODUCT = "ADD_PRODUCT",
  REMOVE_PRODUCT = "REMOVE_PRODUCT",
  UPDATE_QUANTITY = "UPDATE_QUANTITY",
}

type Action = {
  type: CartAction;
  payload: {
    product: Product;
    address: Address;
    type: CartType;
    card_id: string;
    user: any;
    orderedSkuCodes: string[];
    subscribedSkuCodes: string[];
  };
};

const cartReducer = (state: CartState, action: Action): CartState => {
  const {
    type,
    payload: {
      product,
      type: cartType,
      address,
      card_id,
      user,
      orderedSkuCodes,
      subscribedSkuCodes,
    },
  } = action;
  switch (type) {
    case CartAction.ADD_PRODUCT:
      const products = [...state.cart.products];
      products.push(product);
      return {
        ...state,
        cart: { ...state.cart, products: products },
        type: cartType,
      } as CartState;
    case CartAction.REMOVE_PRODUCT:
      const updated_products = state.cart.products.filter(
        (p) => p.id != product.id
      );
      return {
        ...state,
        cart: {
          ...state.cart,
          products: updated_products,
        },
        type: updated_products.length <= 0 ? undefined : cartType,
      } as CartState;
    case CartAction.UPDATE_QUANTITY:
      const new_products = state.cart.products.map((p) => {
        if (p.id == product.id) {
          return product;
        }
        return p;
      });
      return {
        ...state,
        cart: {
          ...state.cart,
          products: new_products,
        },
      } as CartState;
    case CartAction.SET_ADDRESS:
      return {
        ...state,
        cart: { ...state.cart, address: address },
      } as CartState;
    case CartAction.SET_CARD:
      return {
        ...state,
        card_id: card_id,
        user: user,
      } as CartState;
    case CartAction.SET_ORDERED_IDS:
      return {
        ...state,
        subscribedSkuCodes: subscribedSkuCodes,
        orderedSkuCodes: orderedSkuCodes,
      } as CartState;
    default:
      return state;
  }
};

type Props = {
  children: any;
};
const CartProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, {
    cart: { products: [], address: {} as Address },
  } as CartState);

  return <Cart.Provider value={{ state, dispatch }}>{children}</Cart.Provider>;
};

export default CartProvider;
