import React, { useState } from "react";
import withTheme from "../../../providers/theme/withTheme";

type Props = {
  theme: any;
  children: React.ReactNode;
  className?: string;
};
const CardTags: React.FC<Props> = ({ theme, children, className = "" }) => {
  const [cssClass, setCssClass] = useState<string>(
    [theme.card.tags, className].join(" ")
  );
  return <div className={`${cssClass}`}>{children}</div>;
};

export default withTheme(CardTags);
