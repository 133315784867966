import React, { ChangeEventHandler } from "react";
import withTheme from "../../providers/theme/withTheme";
import { Icon, IconColor } from "../typography/icon";
import InputGroup from "./base/input-group";
import InputVariant from "./input-variant";

export type Props = {
  theme: any;
  value: string | number;
  onChange: ChangeEventHandler;
  variant?: InputVariant;
  error?: string;
  label?: string;
  placeholder?: string;
  className?: string;
  helpText?: string;
  type?: "text" | "number" | "password";
};
const SearchField: React.FC<Props> = ({
  theme,
  value,
  onChange,
  label = "",
  error = "",
  variant = InputVariant.FILLED,
  placeholder = "",
  className = "",
  helpText = "",
  type = "text",
}) => {
  const classes = [className];
  if (error) classes.push(theme.input[variant].errorField);
  else classes.push(theme.input[variant].search.field);

  return (
    <InputGroup className="mb-0">
      <div className={theme.input[variant].search.base}>
        <div className={theme.input[variant].search.left}>
          <Icon icon="search" color={IconColor.PRIMARY} className="" />
        </div>
        <input
          type={type}
          className={`${classes.join(" ")}`}
          defaultValue={value}
          onChange={(e) => onChange(e)}
          placeholder={placeholder}
        />
      </div>
    </InputGroup>
  );
};

export default withTheme(SearchField);
