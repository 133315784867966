import React from "react";
import { useNavigate } from "react-router-dom";
import { Action } from "../../../domain/models/action";
import { Card } from "../../../domain/models/card";

type Props = {
  card: Card;
  action: Action;
  onClick: () => void;
};

const ActionItem: React.FC<Props> = ({ card, action, onClick }) => {
  const navigate = useNavigate();
  const onActionClick = () => {
    onClick();
    let link = action.link.replace(":orgCode", card.org_code);
    link = link.replace(":deptCode", card.dept_code);
    link = link.replace(":title", card.organization.name);
    link = link.replace(":cardId", card._id);
    if (action.link.includes("http")) window.open(link);
    else navigate(link);
  };
  return (
    <li
      className="px-6 py-2 border-b border-gray-200 w-full"
      onClick={onActionClick}
    >
      {action.action}
    </li>
  );
};

export default ActionItem;
