import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBars,
  faChartPie,
  faCloudUploadAlt,
  faDownload,
  faExclamationCircle,
  faFilter,
  faInfoCircle,
  faLocation,
  faLocationDot,
  faMinus,
  faPencil,
  faPlus,
  faSearch,
  faSignOutAlt,
  faSpinner,
  faTable,
  faTimes,
  faTimesCircle,
  faTrash,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import withTheme from "../../../providers/theme/withTheme";
import IconColor from "./icon-color";
import { IconSize } from "./icon-size";

const icons = {
  logout: faSignOutAlt,
  logo: faChartPie,
  table: faTable,
  filter: faFilter,
  loading: faSpinner,
  download: faDownload,
  pencil: faPencil,
  upload: faCloudUploadAlt,
  cancel: faTimes,
  warning: faExclamationCircle,
  info: faInfoCircle,
  error: faExclamationCircle,
  growth: faArrowUp,
  loss: faArrowDown,
  arrowRight: faArrowRight,
  arrowLeft: faArrowLeft,
  location: faLocationDot,
  search: faSearch,
  add: faPlus,
  minus: faMinus,
  bars: faBars,
  delete: faTrash,
  wallet: faWallet,
} as any;

export type Props = {
  theme: any;
  icon: string;
  color?: IconColor;
  size?: IconSize;
  spin?: boolean;
  className?: string;
};
const Icon: React.FC<Props> = ({
  theme,
  icon,
  color,
  size = IconSize.xs,
  spin = false,
  className = "",
}) => {
  const classes = [className];
  if (color) classes.push(theme.text[color]);
  classes.push(theme.size[size]);
  return (
    <FontAwesomeIcon
      icon={icons[icon]}
      className={`${classes.join(" ")}`}
      spin={spin}
    />
  );
};

export default withTheme(Icon);
