import React from "react";
import { Button, ButtonColor, ButtonType } from "../../ga-components/buttons";

type Props = {
  close: () => void;
};

const PanCardWarning: React.FC<Props> = ({ close }) => {
  return (
    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <div className="mt-2">
              <p className="text-sm text-gray-500 font-bold">
                You have reached 10k order limit. Please use the app judiciously
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="p-5">
        <div className="grid grid-rows-1 grid-flow-col gap-4">
          <div>
            <Button
              className="w-full"
              color={ButtonColor.PRIMARY}
              type={ButtonType.FILLED}
              text="CLOSE"
              onClick={() => close()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PanCardWarning;
