import React, { useCallback, useState } from "react";
import Swal from "sweetalert2";
import {
  ACTIVE,
  toFullDateString,
  toFullMonthString,
} from "../../../Constants";
import { Order } from "../../../domain/models/order";
import { Subscription } from "../../../domain/models/subscription";
import { CancelSubscription } from "../../../domain/usages/cancel-subscription";
import { Button, ButtonColor, ButtonType } from "../../ga-components/buttons";
import {
  Card,
  CardBody,
  CardColor,
  CardRightContent,
  CardSubHeadline,
  CardSupportingText,
  CardTags,
} from "../../ga-components/cards";
import Chip from "../../ga-components/chip/chip";
import { ChipColor } from "../../ga-components/chip/chip-color";
import { ChipType } from "../../ga-components/chip/chip-type";
import Clickable from "../../ga-components/clickable";
import { Icon, IconColor } from "../../ga-components/typography/icon";
import { IconSize } from "../../ga-components/typography/icon/icon-size";
import SubscriptionStatusChip from "./subscription-status";
import StatusChip from "./subscription-status";

type Props = {
  subscription: Subscription;
  cancelSubscription: CancelSubscription;
  onCancel: Function;
};

const SubscriptionItem: React.FC<Props> = ({
  subscription,
  cancelSubscription,
  onCancel,
}) => {
  const [loading, setLoading] = useState(false);

  const cancel = useCallback(async () => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to cancel this subscription?",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes Confirm!",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.value && subscription && subscription.id) {
        setLoading(true);
        const result = await cancelSubscription.cancel(subscription.id, {});
        if (result.success) {
          Swal.fire({
            icon: "success",
            title: "Subscription request is cancelled.",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: result.errors.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        setLoading(false);
        onCancel();
      }
    });
  }, [cancelSubscription]);
  return (
    <Card color={CardColor.WHITE} className="mb-2 border">
      <CardBody className="flex justify-between">
        <CardSupportingText>#{subscription.id}</CardSupportingText>
        <CardSubHeadline className="">
          {subscription.status && (
            <SubscriptionStatusChip status={subscription.status} />
          )}
        </CardSubHeadline>
      </CardBody>
      <CardBody className="pt-0">
        <div className="grid grid-cols-2 gap-4 justify-items-start justify-start">
          <div className="text-left">
            <CardSupportingText>Starts from:</CardSupportingText>
            <CardSubHeadline className="text-primary">
              {toFullMonthString(subscription.start_date ?? "NA")}
            </CardSubHeadline>
          </div>
          <div className="text-left">
            <CardSupportingText>Ends on:</CardSupportingText>
            <CardSubHeadline className="text-primary">
              {toFullMonthString(subscription.end_date ?? "NA")}
            </CardSubHeadline>
          </div>
        </div>
      </CardBody>
      <CardBody className="pb-0 pt-0">
        <CardSupportingText className="text-left">
          Subscribed Products:
        </CardSupportingText>
      </CardBody>
      {subscription.items.map((i) => (
        <div>
          <CardBody className="text-left flex justify-between border-b">
            <div>
              <CardSubHeadline className="text-sm">
                <div className="">
                  <span>{i.item}</span>{" "}
                </div>
              </CardSubHeadline>
              <CardSupportingText className="text-xs">
                {i.description}
              </CardSupportingText>
            </div>
            <div className="w-12"></div>
            <span className="text-primary">x{i.quantity}</span>
          </CardBody>
        </div>
      ))}
      {subscription.status == ACTIVE && (
        <CardTags className=" flex justify-end">
          <Clickable onClick={cancel}>
            <Chip
              type={ChipType.SUGGESTION}
              color={ChipColor.DANGER}
              text={
                <>
                  {loading && (
                    <span className="w-4 ">
                      <Icon
                        icon="loading"
                        spin={true}
                        color={IconColor.WHITE}
                        size={IconSize.xl}
                      />
                    </span>
                  )}
                  Cancel Subscription
                </>
              }
            />
          </Clickable>
        </CardTags>
      )}
    </Card>
  );
};

export default SubscriptionItem;
