import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AUTH_DOCTOR } from "../../../base";
import { JsonStorage } from "../../../data/protocols/storage/json-storage";
import { Result } from "../../../domain/models/result";
import { GetSurveyUrl } from "../../../domain/usages/get-survey-url";
import CertificateArtwork from "../../components/common/certificate";
import FormArtwork from "../../components/common/form";
import { InputField, InputVariant } from "../../ga-components/inputs";
import { Button, ButtonColor, ButtonType } from "../../ga-components/buttons";
import { NavColor, NavBar } from "../../ga-components/nav";

type Props = {
  storage: JsonStorage;
  getSurveyUrl: GetSurveyUrl;
};

const SurveyPage: React.FC<Props> = ({ storage, getSurveyUrl }) => {
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const [doctor, setDoctor] = useState();
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState<string>();
  const [querySearch] = useSearchParams();
  const [title, setTitle] = useState<string | null>();

  /**
   * Go to previous page
   * If coming from mobile app go back to app
   * If coming from web go to previous page from history
   */
  let goBack = () => {
    let BackButton = (window as { [key: string]: any })["BackButton"];
    if (BackButton) BackButton.postMessage("Hello!");
    else {
      window.open("about:blank", "_self");
      window.close();
    }
  };

  /** Get url for the doctor*/
  const fetchGoogleFormUrl = useCallback(async () => {
    let result: Result = await getSurveyUrl.get({});
    if (result.url) {
      setUrl(result.url);
    }
  }, [getSurveyUrl]);

  useEffect(() => {
    setTitle(querySearch.get("title"));
    if (doctor) {
      fetchGoogleFormUrl();
    }
  }, [doctor]);

  useEffect(() => {
    const doctor: any = JSON.parse(
      storage.get(AUTH_DOCTOR) ?? JSON.stringify({})
    );
    if (doctor) {
      setDoctor(doctor);
    }
  }, []);

  return (
    <>
      <NavBar
        title={title ?? "Survey"}
        subTitle={""}
        color={NavColor.WHITE}
        back={true}
        onBackClick={goBack}
      />
      {url && (
        <div className="flex h-screen bg-white">
          <iframe
            src={url}
            frameBorder="0"
            marginHeight={0}
            marginWidth={0}
            width="100%"
            height="100%"
          ></iframe>
        </div>
      )}
    </>
  );
};

export default SurveyPage;
