import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CreateRewardRequest } from "../../../domain/usages/create-reward-request";
import { GetWalletBalance } from "../../../domain/usages/get-wallet-balance";
import { GetWalletTransactionList } from "../../../domain/usages/get-wallet-transaction-list";
import BalanceView from "../../components/wallet/balance-view";
import WalletTransactionList from "../../components/wallet/wallet-transaction-list";
import { NavBar, NavColor } from "../../ga-components/nav";

type Props = {
  walletToken: string;
  createRewardRequest: CreateRewardRequest;
  getWalletBalance: GetWalletBalance;
  getWalletTransactionList: GetWalletTransactionList;
};

const WalletPage: React.FC<Props> = ({
  getWalletBalance,
  getWalletTransactionList,
  createRewardRequest,
  walletToken,
}) => {
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);

  /**
   * Go to previous page
   * If coming from mobile app go back to app
   * If coming from web go to previous page from history
   */
  let goBack = () => {
    let BackButton = (window as { [key: string]: any })["BackButton"];
    if (BackButton) BackButton.postMessage("Hello!");
    else {
      window.open("about:blank", "_self");
      window.close();
    }
  };

  if (!walletToken) return <>Preparing wallet</>;
  return (
    <>
      <NavBar
        title={"Wallet"}
        subTitle={""}
        color={NavColor.WHITE}
        back={true}
        onBackClick={goBack}
      />
      <div className="bg-white">
        <div>
          <BalanceView
            walletToken={walletToken}
            getWalletBalance={getWalletBalance}
            createRewardRequest={createRewardRequest}
            refresh={refresh}
            onSuccess={() => setRefresh(!refresh)}
          />
        </div>
        <div className="p-2 px-4 h-screen overflow-y-scroll pb-20">
          <p className="p-2 text-sm font-bold text-gray-700">Transactions</p>

          <WalletTransactionList
            walletToken={walletToken}
            getWalletTransactionList={getWalletTransactionList}
            refresh={refresh}
          />
        </div>
      </div>
    </>
  );
};

export default WalletPage;
