import React, { useState } from "react";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";
import { Price } from "../../../domain/models/price";
import { Product } from "../../../domain/models/product";
import { pageRoutes } from "../../../routes";
import { Button, ButtonColor } from "../../ga-components/buttons";
import {
  Card,
  CardBody,
  CardColor,
  CardSubHeadline,
  CardSupportingText,
} from "../../ga-components/cards";
import { Icon } from "../../ga-components/typography/icon";
import { IconSize } from "../../ga-components/typography/icon/icon-size";

type Props = {};

const NavigationModal: React.FC<Props> = ({}) => {
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <div>
      <Button
        color={ButtonColor.WHITE}
        text={<Icon icon="bars" size={IconSize.xl} />}
        onClick={() => setOpen(true)}
      />
      <ReactModal
        isOpen={open}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            display: "flex",
            justifyContent: "center",
          },
          content: {
            position: "relative",
            marginLeft: "auto",
            padding: 0,
            marginTop: "56px",
            borderRadius: "0",
            inset: "auto",
          },
        }}
        onRequestClose={() => setOpen(false)}
        contentLabel="Example Modal"
      >
        <Card
          color={CardColor.PRIMARY}
          className="m-auto w-full h-full rounded-none"
        >
          <ul className="w-56 margin-t-12 text-white">
            <li
              className="px-4 py-2 border-b border-gray-300"
              onClick={() => navigate(pageRoutes.orders)}
            >
              ORDERS
            </li>
            <li
              className="px-4 py-2 border-b border-gray-300"
              onClick={() => navigate(pageRoutes.subscriptions)}
            >
              SUBSCRIPTIONS
            </li>
          </ul>
        </Card>
      </ReactModal>
    </div>
  );
};

export default NavigationModal;
