import React from "react";
import { Subscription } from "../../../domain/models/subscription";
import { CancelSubscription } from "../../../domain/usages/cancel-subscription";
import SubscriptionItem from "./subscription-item";

type Props = {
  subscriptions: Subscription[];
  cancelSubscription: CancelSubscription;
  onCancel: Function;
};

const SubscriptionList: React.FC<Props> = ({
  subscriptions,
  cancelSubscription,
  onCancel,
}) => {
  return (
    <ul>
      {subscriptions &&
        subscriptions.length > 0 &&
        subscriptions.map((s) => (
          <SubscriptionItem
            subscription={s}
            cancelSubscription={cancelSubscription}
            onCancel={onCancel}
          />
        ))}
    </ul>
  );
};

export default SubscriptionList;
