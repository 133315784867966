import {
  CANCELLED,
  DELIVERED,
  DISPATCHED,
  PACKING,
  PENDING,
  READY_TO_DISPATCH,
} from "../../../Constants";
import Chip from "../../ga-components/chip/chip";
import { ChipColor } from "../../ga-components/chip/chip-color";
import { ChipType } from "../../ga-components/chip/chip-type";

type Props = {
  status: string;
};

const StatusChip: React.FC<Props> = ({ status }) => {
  if (status === DELIVERED)
    return (
      <Chip
        text="Delivered"
        type={ChipType.SUGGESTION}
        color={ChipColor.PRIMARY_LOW}
      />
    );
  else if (status === DISPATCHED)
    return (
      <Chip
        text="Dispatched"
        type={ChipType.SUGGESTION}
        color={ChipColor.PRIMARY_LOW}
      />
    );
  else if (status === PENDING)
    return (
      <Chip
        text="Pending"
        type={ChipType.SUGGESTION}
        color={ChipColor.SECONDARY_LOW}
      />
    );
  else if (status === PACKING)
    return (
      <Chip text="Packing" type={ChipType.SUGGESTION} color={ChipColor.GRAY} />
    );
  else if (status === READY_TO_DISPATCH)
    return (
      <Chip
        text="Ready to dispatch"
        type={ChipType.SUGGESTION}
        color={ChipColor.PRIMARY_LOW}
      />
    );
  else if (status === CANCELLED)
    return (
      <Chip
        text="Cancelled"
        type={ChipType.SUGGESTION}
        color={ChipColor.DANGER}
      />
    );
  else
    return (
      <Chip
        text="Pending"
        type={ChipType.SUGGESTION}
        color={ChipColor.SECONDARY_LOW}
      />
    );
};

export default StatusChip;
