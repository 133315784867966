import { Route, Routes } from "react-router-dom";
import "./App.css";
import { AddressFormFactory } from "./main/factories/AddressFormFactory";
import { AuthDataPullPageFactory } from "./main/factories/AuthDataPullPageFactory";
import { AuthPageFactory } from "./main/factories/AuthPageFactory";
import { CartPageFactory } from "./main/factories/CartPageFactory";
import { OrderListPageFactory } from "./main/factories/OrderListPageFactory";
import { ProductPageFactory } from "./main/factories/ProductPageFactory";
import { SubscriptionListPageFactory } from "./main/factories/SubscriptionListPageFactory";
import { SubscriptionPageFactory } from "./main/factories/SubscriptionPageFactory";
import SupportPage from "./presentation/screens/support/SupportPage";
import CartProvider from "./presentation/providers/cart/CartProvider";
import { pageRoutes } from "./routes";
import { WalletPageFactory } from "./main/factories/WalletPageFactory";
import { OtpLoginPageFactory } from "./main/factories/OtpLoginPageFactory";
import { CardListPageFactory } from "./main/factories/CardListPageFactory";
import { ProgramPageFactory } from "./main/factories/ProgramPageFactory";
import { SurveyPageFactory } from "./main/factories/SurveyPageFactory";

function OrderApp() {
  return (
    <CartProvider>
      <div className="h-screen w-screen bg-gray-100">
        <Routes>
          <Route path={pageRoutes.login} element={<OtpLoginPageFactory />} />
          <Route path={pageRoutes.auth} element={<AuthPageFactory />} />
          <Route
            path={pageRoutes.authDataPull}
            element={<AuthDataPullPageFactory />}
          />
          <Route path={pageRoutes.cards} element={<CardListPageFactory />} />
          <Route path={pageRoutes.products} element={<ProductPageFactory />} />
          <Route path={pageRoutes.orders} element={<OrderListPageFactory />} />
          <Route
            path={pageRoutes.subscriptions}
            element={<SubscriptionListPageFactory />}
          />
          <Route
            path={pageRoutes.subscription_summary}
            element={<SubscriptionPageFactory />}
          />
          <Route path={pageRoutes.cart} element={<CartPageFactory />} />
          <Route path={pageRoutes.address} element={<AddressFormFactory />} />
          <Route
            path={pageRoutes.employee_general_support}
            element={<SupportPage type="employee_general" />}
          />
          <Route
            path={pageRoutes.employee_support}
            element={<SupportPage type="employee" />}
          />
          <Route
            path={pageRoutes.doctor_support}
            element={<SupportPage type="doctor" />}
          />
          <Route
            path={pageRoutes.doctor_general_support}
            element={<SupportPage type="doctor_general" />}
          />
          <Route path={pageRoutes.wallet} element={<WalletPageFactory />} />
          <Route path={pageRoutes.programs} element={<ProgramPageFactory />} />
          <Route path={pageRoutes.survey} element={<SurveyPageFactory />} />
        </Routes>
      </div>
    </CartProvider>
  );
}

export default OrderApp;
