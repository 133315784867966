import { useCallback, useEffect, useState } from "react";
import { COD_WALLET_ORG_ID } from "../../../Constants";
import { Result } from "../../../domain/models/result";
import { WalletBalance } from "../../../domain/models/wallet-balance";
import { CreateRewardRequest } from "../../../domain/usages/create-reward-request";
import { GetWalletBalance } from "../../../domain/usages/get-wallet-balance";
import { Button, ButtonColor } from "../../ga-components/buttons";
import BalanceChip from "./balance-chip";
import ConvertButton from "./convert-button";

type Props = {
  walletToken: string;
  getWalletBalance: GetWalletBalance;
  createRewardRequest: CreateRewardRequest;
  refresh: boolean;
  onSuccess?: () => void;
};

const BalanceView: React.FC<Props> = ({
  walletToken,
  getWalletBalance,
  createRewardRequest,
  refresh,
  onSuccess,
}) => {
  const [balance, setBalance] = useState<WalletBalance | undefined>(undefined);

  /**
   * Get wallet balance
   */
  const getBalance = useCallback(async () => {
    const result: Result = await getWalletBalance.get(walletToken, {
      org_id: COD_WALLET_ORG_ID,
    });
    if (result) {
      setBalance({
        total_amount: result.total_amount,
        total_pending_amount: result.total_pending_amount,
        total_converted: result.total_converted,
      });
    }
  }, [getWalletBalance, walletToken]);

  useEffect(() => {
    getBalance();
  }, [getBalance, refresh]);

  if (!balance) {
    return <div></div>;
  }
  return (
    <div className="grid grid-rows-3 grid-flow-col gap-4 p-4">
      <div className="row-start-1 row-end-4">
        <BalanceChip amount={balance.total_amount} title={"Current Balance"} />
      </div>
      <div className="row-start-1 row-end-4">
        <ConvertButton
          title={"Convert"}
          totalAmount={balance.total_amount}
          createRewardRequest={createRewardRequest}
          onSuccess={onSuccess}
        />
      </div>
    </div>
  );
};

export default BalanceView;
