import { BASE_AUTH_API_ENDPOINT } from "../../base";
import { RemoteGenerateOtp } from "../../data/usercases/remote-generate-otp";
import { RemoteVerifyOtp } from "../../data/usercases/remote-verify-otp";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import OtpLoginPage from "../../presentation/screens/auth/OtpLoginPage";

type Props = {};

export const OtpLoginPageFactory = (props: Props) => {
  const storage = LocalJsonStorage.getInstance();

  const axiosHttpClient = AxiosHttpClient.getInstance();

  const generateOtp = new RemoteGenerateOtp(
    `${BASE_AUTH_API_ENDPOINT}/auth/requestOtp`,
    axiosHttpClient
  );

  const verifyOtp = new RemoteVerifyOtp(
    `${BASE_AUTH_API_ENDPOINT}/auth/verifyOtp`,
    axiosHttpClient
  );

  return (
    <OtpLoginPage
      generateOtp={generateOtp}
      verifyOtp={verifyOtp}
      storage={storage}
      {...props}
    />
  );
};
