export enum ChipColor {
    PRIMARY = "primary",
    PRIMARY_MEDIUM = "primaryMedium",
    PRIMARY_LOW = "primaryLow",
    SECONDARY = "secondary",
    SECONDARY_LOW = "secondaryLow",
    SECONDARY_MEDIUM = "secondaryMedium",
    WHITE = "white",
    GRAY = "gray",
    DANGER = "danger",
}
