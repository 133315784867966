import { useEffect } from "react";

let widgetIds = {
  employee: 84000002248,
  doctor: 84000002272,
  employee_general: 84000002234,
  doctor_general: 84000002945,
};

type Props = {
  type: "employee" | "doctor" | "employee_general" | "doctor_general";
};
const SupportPage: React.FC<Props> = ({ type }) => {
  /**
   * Go to previous page
   * If coming from mobile app go back to app
   * If coming from web go to previous page from history
   */
  let goBack = () => {
    window.close();
  };

  useEffect(() => {
    const script = document.createElement("script");

    window.fwSettings = {
      widget_id: widgetIds[type],
    };

    if ("function" != typeof window.FreshworksWidget) {
      var n: any = function () {
        n.q.push(arguments);
      };
      n.q = [];
      window.FreshworksWidget = n;
    }

    script.src = `https://ind-widget.freshworks.com/widgets/${widgetIds[
      type
    ].toString()}.js`;
    script.async = true;

    document.body.appendChild(script);
    window.FreshworksWidget("open");

    return () => {
      document.body.removeChild(script);
    };
  }, [type]);

  return (
    <>
      <div className="mx-auto max-w-2xl lg:max-w-4xl lg:px-12 text-center mt-5">
        <h1 className="font-display text-5xl font-bold tracking-tighter text-blue-600 sm:text-7xl">
          Welcome To ConnectOD Assistance!!
        </h1>
        <div className="mt-6 space-y-6 font-display text-2xl tracking-tight text-blue-900">
          <p>To get assistance click on "Help" button.</p>
        </div>
      </div>
    </>
  );
};

export default SupportPage;
