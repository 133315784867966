import { Result } from "../../domain/models/result";
import { GetSubscribedSkuCodes } from "../../domain/usages/get-subscribed-sku-codes";
import { HttpGetClient } from "../protocols/http/http-get-client";

export class RemoteGetSubscribedProductCodes implements GetSubscribedSkuCodes {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async get(params: GetSubscribedSkuCodes.Params): Promise<Result> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url,
      query: params,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      authHeaders: true,
    });

    return httpResponse.data;
  }
}
