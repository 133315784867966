import { EmptyResponse, Result } from "../../domain/models/result";
import { RegisterForProgram } from "../../domain/usages/register-for-program";
import { HttpPostClient } from "../protocols/http/http-post-client";

export class RemoteRegisterForProgram implements RegisterForProgram {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}

  async register(params: RegisterForProgram.Params): Promise<Result> {
    let headers: any = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    const httpResponse = await this.httpPostClient.post({
      url: this.url,
      body: params,
      headers: headers,
      authHeaders: true,
    });

    if (!httpResponse) {
      let response = EmptyResponse;
      response.errors = { message: "Server error. Please try after sometime." };
      return response;
    }
    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
