import moment from "moment";

export const CATEGORY_LIST = [
  { id: 1, label: "All", value: "ALL" },
  { id: 2, label: "Respiratory", value: "CIPRES" },
  { id: 3, label: "Cardiac", value: "CIPVIT" },
  { id: 4, label: "Acute", value: "CIPACU" },
  { id: 5, label: "Metabolic", value: "CIPVITMET" },
  { id: 6, label: "Urology", value: "CIPURO" },
  { id: 6, label: "Emerging", value: "CIPEMER" },
];

export const SUBSCRIPTION_DURATIONS = [3, 6, 9, 12];

export const EXPECTED_DELIVERY_DATES = [
  { name: "5th of every month", value: 5 },
  { name: "10th of every month", value: 10 },
  { name: "15th of every month", value: 15 },
  { name: "20th of every month", value: 20 },
  { name: "25th of every month", value: 25 },
];

export const ADDRESS_TYPES = [
  { name: "Clinic", value: "Clinic" },
  { name: "Hospital", value: "Hospital" },
  { name: "Other", value: "Other" },
];

export const CONNECT_OD_LOGO =
  "https://lh3.googleusercontent.com/4VeCUm13IR5tZkeI4i6eWI9hHOqt1nnsOeTXtWhN3JM2eN2QiJhOq9QMaRspJ48_QQ=w300";

export const DELIVERED = "delivered";
export const PENDING = "pending";
export const FIELD_PENDING = "field_pending";
export const CFA_PENDING = "cfa_pending";
export const READY_TO_DISPATCH = "ready_to_dispatched";
export const DISPATCHED = "dispatched";
export const CANCELLED = "cancelled";
export const PACKING = "packing";
export const ON_HOLD = "on_hold";
export const CLOSED = "closed";
export const ONBOARDED = "onboarded";
export const VERIFIED = "verified";
export const PFIZER_STEP_UP_PROGRAM = "PFIZER_STEP_UP_PROGRAM";
export const WARNING_CAPPING = 10000;
export const LOWER_CAPPING = 15000;
export const UPPER_CAPPING = 18000;

export const COD_WALLET_ORG_ID = 10;
export const COD_WALLET_VENDOT_ID = 2;

export const ACTIVE = "active";

export const DOCTOR_ROLE_ID = 2;
export const ASSISTANCE_ROLE_ID = 8;
export const SUPPLIER_ROLE_ID = 10;

export function toFullDateString(date: string) {
  return moment(date).format("MMMM DD, Y");
}

export function toFullMonthString(date: string) {
  return moment(date).format("MMMM, Y");
}
