import { WalletTransaction } from "../../../domain/models/wallet-transaction";

type Props = {
  transaction: WalletTransaction;
};

const WalletTransactionCard: React.FC<Props> = ({ transaction }) => {
  return (
    <div className="p-2 pb-2 border-b">
      <div className="float-right">
        <p className="text-xs font-bold text-right text-green-800">
          {transaction.amount}
        </p>
        <p className="text-xs text-right text-gray-500">#{transaction.id}</p>
      </div>
      <div className="mr-16">
        <p className="text-xs text-gray-500 mb-2">#{transaction.id}</p>
        <p className="text-xs font-medium text-gray-700">
          {transaction.title}
          <br />
          {transaction.description}
        </p>
        <p className="text-xs text-gray-500">{transaction.created_at}</p>
      </div>
    </div>
  );
};

export default WalletTransactionCard;
