import React from "react";

type Props = {
  size: number;
};
const LoadingArtwork: React.FC<Props> = ({ size }) => {
  const heightMultiplier = 0.542;
  const widthMultiplier = 0.637;

  const height = size * heightMultiplier;
  const width = size * widthMultiplier;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      width={width}
      height={height}
      viewBox="0 0 637.74841 542.82823"
    >
      <path
        d="M611.76662,259.12555A10.13238,10.13238,0,0,0,626.293,264.637l87.876,28.18777,29.08427-22.818L629.90424,250.15971a10.18731,10.18731,0,0,0-18.13762,8.96584Z"
        transform="translate(-281.1258 -178.58588)"
        fill="#ffb6b6"
      />
      <path
        d="M774.53136,296.79491s-.79628-1.95253-11.31124,2.296c-13.05191,5.27351-82.53736,1.748-86.388-17.19775a7.95987,7.95987,0,0,1-9.66118,1.37546l10.86245-23.84162c5.65419-1.80746,8.72681-3.14475,9.09242-.84209l17-1L772.976,269.17472l1.90309,23.766Z"
        transform="translate(-281.1258 -178.58588)"
        fill="#f2f2f2"
      />
      <polygon
        points="471.753 94.306 505.945 91.346 538 105.999 556 354.999 485.362 322.331 455.055 330.716 471.753 94.306"
        fill="#f2f2f2"
      />
      <polygon
        points="555.874 528.874 542.905 528.873 536.735 478.848 555.876 478.849 555.874 528.874"
        fill="#ffb6b6"
      />
      <path
        d="M840.30747,720.03143l-41.81888-.00155v-.52894a16.27795,16.27795,0,0,1,16.27708-16.27681h.001l7.63877-5.79518,14.25224,5.79606,3.65053.00015Z"
        transform="translate(-281.1258 -178.58588)"
        fill="#2f2e41"
      />
      <polygon
        points="505.615 520.701 492.964 523.557 475.926 476.12 494.598 471.904 505.615 520.701"
        fill="#ffb6b6"
      />
      <path
        d="M792.73634,710.82116l-40.792,9.21027-.11651-.51595A16.278,16.278,0,0,1,764.11964,700.053l.001-.00022,6.17459-7.33548,15.17892,2.51423,3.56089-.804Z"
        transform="translate(-281.1258 -178.58588)"
        fill="#2f2e41"
      />
      <path
        d="M853.42622,401.63264c6.0807,18.33936,5.43784,38.24623-1.46158,59.6117l-2.9494,120.29312c4.87592,5.366,5.62148,53.38029-1.88944,69.0474-10.02979,20.92121-.80606,23.718-7.89591,37.59769H818.6654l-9.50453-62.88069-.67725-50.96857c-2.73126-6.46673-.489-29.22505-.489-29.22505L807.205,497.917,770.1258,580.58486c16.79895,14.37891,15.28678,60.82558,15.56412,81.4269l5.508,24.30944-25.31,2.26263-11.16924-32.96694-12.55779-20.31505c-6.39947-35.99579-8.29687-62.42151-2.841-73.21588l37.22187-138.48633Z"
        transform="translate(-281.1258 -178.58588)"
        fill="#2f2e41"
      />
      <path
        d="M856.06089,395.51182l-3.39-6.78c-4.15-25.71-8.25-49.69-2.68-71.26.35-18.83-5.47-36.52-21.63-46.18l-7.29-4.36-3.69-9.36-33.98.83-.1,12.27-12.17,19.41v.08l.76,105.62,3.36005,10.9-5.07,12.68,2.69,8.09,66.99-10.71,7.79-1.25a13.91763,13.91763,0,0,0,2.59-1.13,14.23178,14.23178,0,0,0,6.22-6.95c.07-.17.13-.33.19-.5a13.93382,13.93382,0,0,0,.9-4.3A14.30328,14.30328,0,0,0,856.06089,395.51182Z"
        transform="translate(-281.1258 -178.58588)"
        fill="#3f3d56"
      />
      <path
        d="M857.55088,402.61186a13.93382,13.93382,0,0,1-.9,4.3l-6.41,7.45a13.91763,13.91763,0,0,1-2.59,1.13l-7.79,1.25,11.27-21.19-24.77-54.23Z"
        transform="translate(-281.1258 -178.58588)"
        opacity="0.2"
      />
      <path
        d="M331.25324,621.71335a148.84015,148.84015,0,0,1-23.51977-1.62835c-7.24828-1.22622-10.92363-2.96749-10.92363-5.17576l.0027-.03994L310.33427,511.919l-6.81012-19.52217a5.40874,5.40874,0,0,1,5.16345-7.18972l44.7844.4664a5.40864,5.40864,0,0,1,5.07455,7.11885l-6.37465,19.12424,13.522,102.95268C365.69663,620.28051,344.04608,621.71335,331.25324,621.71335Z"
        transform="translate(-281.1258 -178.58588)"
        fill="#f2f2f2"
      />
      <rect
        x="24.60242"
        y="428.6351"
        width="21.8758"
        height="1.23012"
        fill="#ccc"
      />
      <rect
        x="24.60242"
        y="421.25437"
        width="21.8758"
        height="1.23012"
        fill="#ccc"
      />
      <rect
        x="24.60242"
        y="413.87365"
        width="21.8758"
        height="1.23012"
        fill="#ccc"
      />
      <rect
        x="24.60242"
        y="406.49292"
        width="21.8758"
        height="1.23012"
        fill="#ccc"
      />
      <rect
        x="24.60242"
        y="399.11219"
        width="21.8758"
        height="1.23012"
        fill="#ccc"
      />
      <path
        d="M528.07264,621.71335a148.84015,148.84015,0,0,1-23.51977-1.62835c-7.24828-1.22622-10.92363-2.96749-10.92363-5.17576l.0027-.03994L507.15367,511.919l-6.81012-19.52217a5.40874,5.40874,0,0,1,5.16345-7.18972l44.7844.4664a5.40864,5.40864,0,0,1,5.07455,7.11885l-6.37465,19.12424,13.522,102.95268C562.516,620.28051,540.86548,621.71335,528.07264,621.71335Z"
        transform="translate(-281.1258 -178.58588)"
        fill="#f2f2f2"
      />
      <rect
        x="221.42182"
        y="428.6351"
        width="21.8758"
        height="1.23012"
        fill="#ccc"
      />
      <rect
        x="221.42182"
        y="421.25437"
        width="21.8758"
        height="1.23012"
        fill="#ccc"
      />
      <rect
        x="221.42182"
        y="413.87365"
        width="21.8758"
        height="1.23012"
        fill="#ccc"
      />
      <rect
        x="221.42182"
        y="406.49292"
        width="21.8758"
        height="1.23012"
        fill="#ccc"
      />
      <rect
        x="221.42182"
        y="399.11219"
        width="21.8758"
        height="1.23012"
        fill="#ccc"
      />
      <path
        d="M435.81354,621.71335a148.8401,148.8401,0,0,1-23.51976-1.62835c-7.24828-1.22622-10.92363-2.96749-10.92363-5.17576l.0027-.03994L414.89457,511.919l-6.81011-19.52217a5.40874,5.40874,0,0,1,5.16345-7.18972l44.7844.4664a5.40864,5.40864,0,0,1,5.07455,7.11885l-6.37465,19.12424,13.522,102.95268C470.25694,620.28051,448.60638,621.71335,435.81354,621.71335Z"
        transform="translate(-281.1258 -178.58588)"
        fill="#f2f2f2"
      />
      <rect
        x="129.16273"
        y="428.6351"
        width="21.8758"
        height="1.23012"
        fill="#ccc"
      />
      <rect
        x="129.16273"
        y="421.25437"
        width="21.8758"
        height="1.23012"
        fill="#ccc"
      />
      <rect
        x="129.16273"
        y="413.87365"
        width="21.8758"
        height="1.23012"
        fill="#ccc"
      />
      <rect
        x="129.16273"
        y="406.49292"
        width="21.8758"
        height="1.23012"
        fill="#ccc"
      />
      <rect
        x="129.16273"
        y="399.11219"
        width="21.8758"
        height="1.23012"
        fill="#ccc"
      />
      <path
        d="M581.27538,629.36317H539.45126V559.24626h41.82412a8.61085,8.61085,0,0,0,0-17.2217H289.73664a8.61085,8.61085,0,0,0,0,17.2217h35.67352v70.11691H289.73664a8.61084,8.61084,0,0,0,0,17.22169H581.27538a8.61085,8.61085,0,1,0,0-17.22169Zm-238.64352,0V559.24626h179.5977v70.11691Z"
        transform="translate(-281.1258 -178.58588)"
        fill="#ccc"
      />
      <path
        d="M574.50248,720.14736c-21.39214,0-41.34371-1.38272-56.17793-3.89246-18.30031-3.09541-27.19656-7.59353-27.19656-13.75148l.01-.19427,32.12035-244.57829L507.1771,411.63191a14.15693,14.15693,0,0,1,1.89052-13.07511,13.99809,13.99809,0,0,1,11.80167-5.968L627.35717,393.698A14.32488,14.32488,0,0,1,640.798,412.55253l-15.05348,45.16118,32.12606,244.59973v.09428c.00357,6.63933-10.06828,11.35314-30.79048,14.41141C606.72281,719.82311,583.52943,720.14736,574.50248,720.14736Z"
        transform="translate(-281.1258 -178.58588)"
        fill="#fff"
      />
      <path
        d="M574.50248,720.14736c-21.39214,0-41.34371-1.38272-56.17793-3.89246-18.30031-3.09541-27.19656-7.59353-27.19656-13.75148l.01-.19427,32.12035-244.57829L507.1771,411.63191a14.15693,14.15693,0,0,1,1.89052-13.07511,13.99809,13.99809,0,0,1,11.80167-5.968L627.35717,393.698A14.32488,14.32488,0,0,1,640.798,412.55253l-15.05348,45.16118,32.12606,244.59973v.09428c.00357,6.63933-10.06828,11.35314-30.79048,14.41141C606.72281,719.82311,583.52943,720.14736,574.50248,720.14736ZM520.575,395.51139a11.12691,11.12691,0,0,0-9.13123,4.75238,11.26344,11.26344,0,0,0-1.50413,10.40395l16.30979,46.7546-.045.34425L494.0477,702.59913c.00571,2.39833,3.22253,7.12785,24.76537,10.77034,14.67781,2.484,34.45511,3.85247,55.68941,3.85247,18.66455,0,38.16045-1.23273,52.15048-3.29681,17.87177-2.63688,28.16717-6.79932,28.29073-11.42885L622.7562,457.428l15.26633-45.8004a11.4,11.4,0,0,0-10.69536-15.00421l-106.5043-1.10917C520.74,395.5121,520.65717,395.51139,520.575,395.51139Z"
        transform="translate(-281.1258 -178.58588)"
        fill="#3f3d56"
      />
      <path
        d="M635.205,672.57842H513.8l19.24546-130.13786.52426-3.58693,8.01772-54.17689c.48909-3.30482,4.29381-.73843,6.98389-2.71943,16.70216-12.29957,33.60422-12.65628,50.653-.80149,2.63527,1.83245,6.27558-.31214,6.76722,2.85976l8.42285,54.34133.814,5.25628Z"
        transform="translate(-281.1258 -178.58588)"
        fill="#2bcbba"
        opacity="0.5"
      />
      <path
        d="M620.22146,690.80174H528.65422A16.70586,16.70586,0,0,1,512.128,671.6508l26.753-180.909a5.30878,5.30878,0,0,1,8.58556-3.34681c16.30479,13.06155,34.07515,12.76729,52.81613-.86991a5.21219,5.21219,0,0,1,5.10234-.59423,5.29753,5.29753,0,0,1,3.22611,4.10673l28.12,181.50035a16.70713,16.70713,0,0,1-16.50977,19.26378Z"
        transform="translate(-281.1258 -178.58588)"
        fill="#2bcbba"
      />
      <rect
        x="250.22673"
        y="491.00656"
        width="52.02406"
        height="2.92542"
        fill="#fff"
      />
      <rect
        x="250.22673"
        y="473.45403"
        width="52.02406"
        height="2.92542"
        fill="#fff"
      />
      <rect
        x="250.22673"
        y="455.90151"
        width="52.02406"
        height="2.92542"
        fill="#fff"
      />
      <rect
        x="250.22673"
        y="438.34899"
        width="52.02406"
        height="2.92542"
        fill="#fff"
      />
      <rect
        x="250.22673"
        y="420.79646"
        width="52.02406"
        height="2.92542"
        fill="#fff"
      />
      <path
        d="M617.68171,553.33617a39.41974,39.41974,0,0,1-30.93635,11.32145c-6.46516-.58512-12.35992-2.99863-18.03525-6.041-5.86539-3.14486-11.54071-6.904-18.03515-8.65931-5.69-1.53583-12.433-1.74063-17.31853,2.06247a2.37429,2.37429,0,0,1-2.80838.07312l.55584-3.803c5.44124-3.8031,12.05272-4.38813,18.53251-3.08639,13.04735,2.633,22.94993,13.42776,36.31912,14.97823A35.03422,35.03422,0,0,0,616.804,547.77789l.01465-.01465Z"
        transform="translate(-281.1258 -178.58588)"
        fill="#fff"
      />
      <path
        d="M918.8742,720.22411a1.18647,1.18647,0,0,1-1.19006,1.19h-635.29a1.19,1.19,0,0,1,0-2.38h635.29A1.18651,1.18651,0,0,1,918.8742,720.22411Z"
        transform="translate(-281.1258 -178.58588)"
        fill="#ccc"
      />
      <polygon
        points="505.753 91.306 539.945 88.346 572 102.999 590 351.999 519.362 319.331 489.055 327.716 505.753 91.306"
        fill="#f2f2f2"
      />
      <circle cx="337" cy="84.99898" r="6" fill="#2bcbba" />
      <circle cx="327" cy="157.99898" r="6" fill="#2bcbba" />
      <circle cx="295" cy="200.99898" r="6" fill="#2bcbba" />
      <path
        d="M725.91142,432.07228V367.66283a4.92267,4.92267,0,0,1,4.9171-4.91711H779.472a4.92266,4.92266,0,0,1,4.91708,4.91711v64.40945a4.92266,4.92266,0,0,1-4.91708,4.9171H730.82852A4.92267,4.92267,0,0,1,725.91142,432.07228Z"
        transform="translate(-281.1258 -178.58588)"
        fill="#e6e6e6"
      />
      <path
        d="M729.64267,412.61793V371.20969a4.61014,4.61014,0,0,1,4.605-4.605h41.80493a4.61034,4.61034,0,0,1,4.60525,4.605v57.31565a4.61033,4.61033,0,0,1-4.60525,4.605H750.15533A20.53573,20.53573,0,0,1,729.64267,412.61793Z"
        transform="translate(-281.1258 -178.58588)"
        fill="#fff"
      />
      <path
        d="M736.41142,382.76334a4.50491,4.50491,0,0,1,4.5-4.5h29a4.5,4.5,0,0,1,0,9h-29A4.50492,4.50492,0,0,1,736.41142,382.76334Z"
        transform="translate(-281.1258 -178.58588)"
        fill="#e6e6e6"
      />
      <path
        d="M736.41142,396.76334a4.50491,4.50491,0,0,1,4.5-4.5h29a4.5,4.5,0,0,1,0,9h-29A4.50492,4.50492,0,0,1,736.41142,396.76334Z"
        transform="translate(-281.1258 -178.58588)"
        fill="#e6e6e6"
      />
      <path
        d="M736.41142,410.76334a4.50491,4.50491,0,0,1,4.5-4.5h29a4.5,4.5,0,0,1,0,9h-29A4.50492,4.50492,0,0,1,736.41142,410.76334Z"
        transform="translate(-281.1258 -178.58588)"
        fill="#e6e6e6"
      />
      <path
        d="M765.53846,443.18436a10.13237,10.13237,0,0,0,4.66868-14.81878l35.27015-32.04029,12.37292-47.86177-21.959-1.827-6.71257,37.558-33.63217,41.39667a10.1873,10.1873,0,0,0,9.992,17.59314Z"
        transform="translate(-281.1258 -178.58588)"
        fill="#ffb6b6"
      />
      <path
        d="M821.23383,305.23982s5.35214.07924,2.94135,11.16085c-1.93,8.87167-9.25193,58.79662-15.09576,69.96563a7.9599,7.9599,0,0,1,1.92766,9.56632l-24.42574-9.47622c-2.129-5.54113,1.44076-7.26351,3.71864-7.76067l-.447-17.381,3.50164-44.30415,24.01145-11.89672Z"
        transform="translate(-281.1258 -178.58588)"
        fill="#f2f2f2"
      />
      <path
        d="M828.42811,223.222a31.64654,31.64654,0,0,1-62.71487-8.53552l.06113-.44921a31.64654,31.64654,0,0,1,62.644,8.98341Z"
        transform="translate(-281.1258 -178.58588)"
        fill="#ffb8b8"
      />
      <path
        d="M820.37364,239.52228c1.62516-3.30126,3.29465-6.84554,2.83793-10.49668s-4.03735-7.141-7.55332-6.05592c-2.10248.64886-3.69154,2.725-5.88931,2.83126-3.024.14618-4.75773-3.31468-5.699-6.19213l-3.83342-11.7191a31.70821,31.70821,0,0,1-26.335,5.951c-3.55922-.81376-7.155-2.40036-9.2428-5.39556s-2.07527-7.64129.78837-9.9062c1.40412-1.11055,3.273-1.5325,4.61484-2.71753a5.17113,5.17113,0,0,0-4.12923-8.99869l9.6522-1.20635-2.87587-5.20135c2.07217,1.63353,4.9562,1.85321,7.55306,1.38563s5.04259-1.53682,7.583-2.24987c12.19585-3.42314,26.3699,2.52874,32.4656,13.63276a12.35592,12.35592,0,0,1,13.47187,3.89c2.58387,3.27949,3.28656,7.65591,3.38667,11.8298a51.32416,51.32416,0,0,1-5.23183,23.77477,19.47726,19.47726,0,0,1-4.39512,6.23455,8.79662,8.79662,0,0,1-7.06107,2.3092"
        transform="translate(-281.1258 -178.58588)"
        fill="#2f2e41"
      />
    </svg>
  );
};

export default LoadingArtwork;
