import {
  AUTH_TOKEN_HEADER,
  AUTH_TOKEN_KEY,
  BASE_AUTH_API_ENDPOINT,
  BASE_DOCTOR_API_ENDPOINT,
} from "../../base";
import { RemoteGetDoctor } from "../../data/usercases/remote-get-doctor";
import { RemoteGetMyDoctor } from "../../data/usercases/remote-get-my-doctor";
import { RemoteGetUser } from "../../data/usercases/remote-get-user";
import { RemoteGetUserGroupToken } from "../../data/usercases/remote-get-user-group-token";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import AuthDataPullPage from "../../presentation/screens/auth/AuthDataPullPage";

type Props = {};

export const AuthDataPullPageFactory = (props: Props) => {
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN_KEY);

  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({
    [AUTH_TOKEN_HEADER]: token,
  });

  const getUser = new RemoteGetUser(
    `${BASE_AUTH_API_ENDPOINT}/auth/me`,
    axiosHttpClient
  );

  const getDoctor = new RemoteGetDoctor(
    `${BASE_DOCTOR_API_ENDPOINT}/doctors/self`,
    axiosHttpClient
  );

  const getMyDoctor = new RemoteGetMyDoctor(
    `${BASE_DOCTOR_API_ENDPOINT}/assistants/doctor`,
    axiosHttpClient
  );

  const getUserGroupToken = new RemoteGetUserGroupToken(
    `${BASE_AUTH_API_ENDPOINT}/users/product-group-token`,
    axiosHttpClient
  );

  return (
    <AuthDataPullPage
      getUser={getUser}
      getDoctor={getDoctor}
      getMyDoctor={getMyDoctor}
      getUserGroupToken={getUserGroupToken}
      storage={storage}
      {...props}
    />
  );
};
