import React from "react";
import { Card } from "../../../domain/models/card";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper";
import { BottomSheet } from "react-spring-bottom-sheet";
import CardActions from "./CardActions";
import "react-spring-bottom-sheet/dist/style.css";

type Props = {
  card: Card;
};

const SingleCard: React.FC<Props> = ({ card }) => {
  const [showActions, setShowActions] = React.useState(false);
  return (
    <>
      <div className="flex justify-center">
        <div className="rounded-lg shadow-lg bg-white max-w-sm w-full">
          <Swiper
            slidesPerView={1}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="h-64"
          >
            {card.slider_images.map((image, index) => {
              return (
                <SwiperSlide className="max-h-64">
                  <img
                    src={image}
                    className="block object-contain rounded-t-lg"
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className="p-6">
            <h5 className="text-gray-900 text-xl font-medium mb-2  line-clamp-2">
              {card.title}
            </h5>
            <p className="text-gray-700 text-base mb-4 line-clamp-3">
              {card.description}
            </p>
            <button
              type="button"
              onClick={() => setShowActions(true)}
              className=" inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            >
              ACTIONS
            </button>
            <BottomSheet open={showActions}>
              <CardActions card={card} onClick={() => setShowActions(false)} />
            </BottomSheet>
            {}
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleCard;
