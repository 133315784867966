import React from "react";
import Select from "react-select";
import { InputVariant } from ".";
import withTheme from "../../providers/theme/withTheme";
import InputGroup from "./base/input-group";
import { Option } from "./option";

type Props = {
  theme: any;
  value: Option;
  options: Option[];
  onChange: Function;
  variant?: InputVariant;
  label?: string;
  placeholder?: string;
  error?: string;
  helpText?: string;
  className?: string;
};
const ButtonSelectField: React.FC<Props> = ({
  theme,
  value,
  options,
  onChange,
  variant = InputVariant.FILLED,
  label = "",
  error = "",
  helpText = "",
  className = "",
}) => {
  const classes = [className];
  if (error) classes.push(theme.input[variant].buttonSelect.errorField);
  else classes.push(theme.input[variant].buttonSelect.base);

  const onOptionChange = (value: any) => {
    const selectedOption = options.find((o) => o.value === value);
    if (selectedOption) onChange(selectedOption);
  };

  return (
    <InputGroup
      label={label}
      error={error}
      helpText={helpText}
      variant={variant}
    >
      <div className={classes.join(" ")}>
        {options.map((o) => {
          return (
            <div
              className={`${theme.input[variant].buttonSelect.option} ${
                value.value == o.value
                  ? theme.input[variant].buttonSelect.active
                  : theme.input[variant].buttonSelect.pending
              }`}
              onClick={() => onOptionChange(o.value)}
            >
              {o.value}
            </div>
          );
        })}
      </div>
    </InputGroup>
  );
};

export default withTheme(ButtonSelectField);
