import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import OtpField from "react-otp-field";
import { useNavigate } from "react-router-dom";
import { AUTH_TOKEN_KEY, AUTH_USER } from "../../../base";
import { JsonStorage } from "../../../data/protocols/storage/json-storage";
import { Result } from "../../../domain/models/result";
import { GenerateOtp } from "../../../domain/usages/generate-otp";
import { VerifyOtp } from "../../../domain/usages/verify-otp";
import { pageRoutes } from "../../../routes";
import { Button, ButtonColor, ButtonType } from "../../ga-components/buttons";
import { InputField, InputVariant } from "../../ga-components/inputs";

type GenerateOtpForm = {
  mobile: string;
  message?: string;
};

type VerifyOtpForm = {
  mobile: string;
  otp: string;
};

type Props = {
  generateOtp: GenerateOtp;
  verifyOtp: VerifyOtp;
  storage: JsonStorage;
};
const OtpLoginPage: React.FC<Props> = ({ generateOtp, verifyOtp, storage }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [otpSent, setOtpSent] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<GenerateOtpForm>({
    defaultValues: {
      mobile: "",
    },
  });

  const {
    handleSubmit: handleSubmitVerify,
    control: controlVerify,
    setValue,
  } = useForm<VerifyOtpForm>({
    defaultValues: {
      mobile: "",
      otp: "",
    },
  });

  const requestOtp = useCallback(
    async (data: GenerateOtpForm) => {
      setLoading(true);
      let result: Result = await generateOtp.generate(data);
      if (result.success) {
        setOtpSent(true);
        setValue("mobile", data.mobile);
        setErrorMessage("");
      } else if (result.errors) {
        setErrorMessage("Account for this mobile number doesn't exist");
      }
      setLoading(false);
    },
    [generateOtp]
  );

  const verifyMobileOtp = useCallback(
    async (data: VerifyOtpForm) => {
      setLoading(true);
      let result: Result = await verifyOtp.verify(data);
      if (result.success && result.user) {
        storage.add(AUTH_TOKEN_KEY, result.user.auth_token);
        navigate(pageRoutes.authDataPull + "?continue=" + encodeURI("/cards"));
      }
      setLoading(false);
    },
    [verifyOtp]
  );
  return (
    <>
      <section className="h-full gradient-form bg-gray-200 md:h-screen">
        <div className="py-12 px-6 h-full overflow-y-scroll">
          <div className="flex justify-center items-center flex-wrap h-full g-6 text-gray-800">
            <div className="xl:w-10/12">
              <div className="block bg-white shadow-lg rounded-lg">
                <div className="lg:flex lg:flex-wrap g-0 mb-10">
                  <div className="lg:w-6/12 px-4 md:px-0">
                    <div className="md:p-12 md:mx-6">
                      <div className="text-center">
                        <img
                          className="mx-auto w-48"
                          src="/cod-logo.png"
                          alt="logo"
                        />
                        <h4 className="text-xl font-semibold mt-1 mb-12 pb-1">
                          Welcome to Connect On Demand
                        </h4>
                      </div>
                      <p className="mb-4">
                        Please login using your mobile number.
                      </p>
                      {errorMessage && (
                        <p className="mb-4 text-red-500 text-sm">
                          {errorMessage}
                        </p>
                      )}
                      <div className="mb-4">
                        <Controller
                          name="mobile"
                          control={control}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <InputField
                              disabled={otpSent}
                              type="number"
                              value={value}
                              onChange={(e: any) => {
                                if (e.target && e.target.value)
                                  onChange(e.target.value);
                                else onChange("");
                              }}
                              variant={InputVariant.FILLED_NEW_PRIMARY}
                              placeholder=""
                              error={error && error.message}
                              label="Mobile"
                            />
                          )}
                          rules={{
                            required: { value: true, message: "Required" },
                            pattern: {
                              value: /([1-9]){1}([0-9]){9}$/,
                              message: "Invalid mobile number",
                            },
                          }}
                        />
                        <a
                          className="text-new_primary text-sm cursor-pointer float-right"
                          onClick={handleSubmit(requestOtp)}
                        >
                          Resent OTP
                        </a>
                      </div>
                      {otpSent && (
                        <>
                          <p className="mb-1 text-center text-new_primary">
                            OTP
                          </p>
                          <div className="mb-4">
                            <Controller
                              name="otp"
                              control={controlVerify}
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <OtpField
                                  value={value}
                                  onChange={(value: any) => {
                                    onChange(value);
                                    console.log(value);
                                  }}
                                  numInputs={6}
                                  autoFocus
                                  separator={<span>-</span>}
                                  inputProps={{
                                    className:
                                      "bg-gray-50 border border-new_primary_medium pl-4 pr-3 h-12 w-12 rounded-lg focus:outline-none text-black",
                                    disabled: false,
                                  }}
                                />
                              )}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Required",
                                },
                              }}
                            />
                          </div>
                        </>
                      )}
                      {!otpSent && (
                        <div className="text-center pt-1 mb-12 pb-1">
                          <Button
                            loading={loading}
                            className="w-full"
                            color={ButtonColor.NEW_PRIMARY}
                            type={ButtonType.FILLED}
                            text="SUBMIT"
                            onClick={handleSubmit(requestOtp)}
                          />
                        </div>
                      )}
                      {otpSent && (
                        <div className="text-center pt-1 mb-12 pb-1">
                          <Button
                            loading={loading}
                            className="w-full"
                            color={ButtonColor.NEW_PRIMARY}
                            type={ButtonType.FILLED}
                            text="VERIFY"
                            onClick={handleSubmitVerify(verifyMobileOtp)}
                          />
                        </div>
                      )}
                      {/* <div className="flex items-center justify-between pb-6">
                        <p className="mb-0 mr-2">Forgot password?</p>
                      </div> */}
                      {otpSent && (
                        <div
                          className="flex items-center justify-between pb-6 cursor-pointer"
                          onClick={() => setOtpSent(false)}
                        >
                          <p className="mb-0 mr-2">
                            Try with different number.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className="lg:w-6/12 flex items-center lg:rounded-r-lg rounded-b-lg lg:rounded-bl-none"
                    style={{
                      background:
                        "linear-gradient(to right, #2980b9, #3498db, #1abc9c, #16a085)",
                    }}
                  >
                    <div className="text-white px-4 py-6 md:p-12 md:mx-6">
                      <h4 className="text-xl font-semibold mb-6">
                        Connecting Doctors with Pharmaceutical Companies
                      </h4>
                      <p className="text-sm">
                        Connect on Demand (ConnectOD) offers healthcare
                        professionals a unified, digitally-enabled business
                        model -- connecting them to patients, pharma companies
                        and Channel Partners to provide an enhanced and
                        end-to-end customer experience for patients.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OtpLoginPage;
