import { ACTIVE } from "../../../Constants";
import Chip from "../../ga-components/chip/chip";
import { ChipColor } from "../../ga-components/chip/chip-color";
import { ChipType } from "../../ga-components/chip/chip-type";

type Props = {
  status: string;
};

const SubscriptionStatusChip: React.FC<Props> = ({ status }) => {
  if (status === ACTIVE)
    return (
      <Chip
        text="Active"
        type={ChipType.FILTER}
        color={ChipColor.PRIMARY_LOW}
      />
    );
  else
    return (
      <Chip
        text="Cancelled"
        type={ChipType.SUGGESTION}
        color={ChipColor.DANGER}
      />
    );
};

export default SubscriptionStatusChip;
