import React from "react";
import { Card } from "../../../domain/models/card";
import ActionItem from "./ActionItem";

type Props = {
  card: Card;
  onClick: () => void;
};

const CardActions: React.FC<Props> = ({ card, onClick }) => {
  return (
    <>
      <div className="flex justify-center">
        <ul className="bg-white rounded-lg border border-gray-200 text-gray-900 w-full">
          {card.actions.map((action, index) => {
            return (
              <ActionItem
                card={card}
                action={action}
                key={index}
                onClick={onClick}
              />
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default CardActions;
