import { BASE_DOCTOR_API_ENDPOINT, POSTALCODE_API_ENDPOINT } from "../../base";
import { RemoteAddDeliveryAddress } from "../../data/usercases/remote-add-delivery-address";
import { RemoteFindPostalAddressForPincode } from "../../data/usercases/remote-find-postal-address-for-pincode";
import { RemoteUpdateDeliveryAddress } from "../../data/usercases/remote-update-delivery-address";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import AddressForm from "../../presentation/components/products/address-form";

type Props = {};

export const AddressFormFactory = (props: Props) => {
  const axiosHttpClient = AxiosHttpClient.getInstance();

  const findPostalAddressForPincode = new RemoteFindPostalAddressForPincode(
    `${POSTALCODE_API_ENDPOINT}:pincode`,
    axiosHttpClient
  );

  const addDeliveryAddress = new RemoteAddDeliveryAddress(
    `${BASE_DOCTOR_API_ENDPOINT}/doctors/delivery-addresses`,
    axiosHttpClient
  );

  const updateDeliveryAddress = new RemoteUpdateDeliveryAddress(
    `${BASE_DOCTOR_API_ENDPOINT}/doctors/delivery-addresses/:id`,
    axiosHttpClient
  );
  return (
    <AddressForm
      findPostalAddressForPincode={findPostalAddressForPincode}
      addDeliveryAddress={addDeliveryAddress}
      updateDeliveryAddress={updateDeliveryAddress}
      {...props}
    />
  );
};
