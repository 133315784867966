import React, { useEffect } from "react";
import { DOCTOR_ROLE_ID } from "../../../Constants";
import { Product } from "../../../domain/models/product";
import {
  Card,
  CardBody,
  CardColor,
  CardImage,
  CardSubHeadline,
  CardSupportingText,
  CardTags,
} from "../../ga-components/cards";
import { CartState } from "../../providers/cart/CartContext";
import withCart from "../../providers/cart/withCart";
import AddToCart from "./add-to-cart";

type Props = {
  cart: CartState;
  cartOperationEventEmitter: Function;
  product: Product;
};

const ProductCard: React.FC<Props> = ({
  cart,
  cartOperationEventEmitter,
  product,
}) => {
  const { user } = cart;

  return (
    <>
      <Card color={CardColor.WHITE} className="shadow-md rounded-lg">
        <CardBody className="p-px bg-gray-300">
          <CardImage
            image={product.image.path}
            className="h-32 rounded-t-lg overflow-hidden w-full"
          />
        </CardBody>
        <CardBody className="text-left">
          <CardSubHeadline>{product.name}</CardSubHeadline>
          <CardSupportingText>{product.description}</CardSupportingText>
        </CardBody>
        <CardTags className="p-0 rounded-b-lg overflow-hidden">
          {user && user["role_id"] === DOCTOR_ROLE_ID && (
            <AddToCart product={product} />
          )}
        </CardTags>
      </Card>
    </>
  );
};

export default withCart(ProductCard);
