import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  AUTH_DOCTOR,
  AUTH_TOKEN_KEY,
  AUTH_USER,
  AUTH_USER_GROUP_TOKEN,
} from "../../../base";
import {
  ASSISTANCE_ROLE_ID,
  CATEGORY_LIST,
  DOCTOR_ROLE_ID,
  SUPPLIER_ROLE_ID,
} from "../../../Constants";
import { JsonStorage } from "../../../data/protocols/storage/json-storage";
import { Result } from "../../../domain/models/result";
import { GetDoctor } from "../../../domain/usages/get-doctor";
import { GetMyDoctor } from "../../../domain/usages/get-my-doctor";
import { GetUser } from "../../../domain/usages/get-user";
import { GetUserGroupToken } from "../../../domain/usages/get-user-group-token";
import LoadingArtwork from "../../components/common/loading";
import { TabModel } from "../../ga-components/tabs";

const FilterTabs = CATEGORY_LIST.map(
  (c) => ({ id: c.value, text: c.label, value: c.value } as TabModel)
);

type Props = {
  getUser: GetUser;
  getDoctor: GetDoctor;
  getMyDoctor: GetMyDoctor;
  getUserGroupToken: GetUserGroupToken;
  storage: JsonStorage;
};
const AuthDataPullPage: React.FC<Props> = ({
  getUser,
  getDoctor,
  getMyDoctor,
  getUserGroupToken,
  storage,
}) => {
  const [querySearch] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);

  const fetchLoggedInUser = useCallback(async () => {
    setLoading(true);
    let result: Result = await getUser.get({});
    if (result.user) {
      return result.user;
    }
    return false;
  }, [getUser]);

  const fetchUserGroupToken = useCallback(async () => {
    setLoading(true);
    let result: Result = await getUserGroupToken.get({});
    if (result.product_group_token) {
      return result.product_group_token;
    }
    return false;
  }, [getUserGroupToken]);

  const fetchLoggedInDoctor = useCallback(async () => {
    setLoading(true);
    let result: Result = await getDoctor.get({});
    if (result.doctor) {
      return result.doctor;
    }
    return false;
  }, [getDoctor]);

  const fetchMyDoctor = useCallback(async () => {
    setLoading(true);
    let result: Result = await getMyDoctor.get({});
    if (result.doctor) {
      return result.doctor;
    }
    return false;
  }, [getMyDoctor]);

  useEffect(() => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const nextPage = querySearch.get("continue");

    if (token && nextPage) {
      setLoading(true);
      fetchLoggedInUser()
        .then((user: any) => {
          if (user) {
            storage.add(AUTH_USER, JSON.stringify(user));
            if (user["role_id"] == DOCTOR_ROLE_ID) {
              prepareForDoctor(nextPage);
            } else if (user["role_id"] == ASSISTANCE_ROLE_ID) {
              prepareForAssistance(nextPage);
            } else if (user["role_id"] == SUPPLIER_ROLE_ID) {
              window.location.href = nextPage;
            } else {
              window.location.href = nextPage;
            }
          }
        })
        .catch(() => {
          console.log("Something went wrong while fetching doctor data.");
        });
    }
  }, []);

  /**
   * Prepare data for doctor login
   *
   * @param nextPage
   */
  const prepareForDoctor = async (nextPage: string) => {
    Promise.all([fetchLoggedInDoctor(), fetchUserGroupToken()]).then(
      (result: any) => {
        if (result[0]) {
          storage.add(AUTH_DOCTOR, JSON.stringify(result[0]));
          if (result[1]) {
            storage.add(AUTH_USER_GROUP_TOKEN, result[1]);
          }
          setTimeout(() => {
            window.location.href = nextPage;
          }, 200);
        }
      }
    );
  };

  /**
   * Prepare data for assistance login
   *
   * @param nextPage
   */
  const prepareForAssistance = async (nextPage: string) => {
    Promise.all([fetchMyDoctor(), fetchUserGroupToken()]).then(
      (result: any) => {
        if (result[0]) {
          storage.add(AUTH_DOCTOR, JSON.stringify(result[0]));
          if (result[1]) {
            storage.add(AUTH_USER_GROUP_TOKEN, result[1]);
          }
          setTimeout(() => {
            window.location.href = nextPage;
          }, 200);
        }
      }
    );
  };

  return (
    <>
      <div className="flex h-screen bg-white">
        <div className="m-auto justify-center">
          <div>
            <LoadingArtwork size={400} />
            <div className="mt-2 capital text-lg text-center text-gray-500">
              Almost there, verifying details...
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthDataPullPage;
