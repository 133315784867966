enum NavColor {
  PRIMARY = "primary",
  PRIMARY_MEDIUM = "primaryMedium",
  PRIMARY_LOW = "primaryLow",
  SECONDARY = "secondary",
  SECONDARY_MEDIUM = "secondaryMedium",
  SECONDARY_LOW = "secondaryLow",
  WHITE = "white",
  SECONDARY_WHITE = "secondaryWhite",
}

export default NavColor;
