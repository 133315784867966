import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CATEGORY_LIST } from "../../../Constants";
import { Order } from "../../../domain/models/order";
import { Result } from "../../../domain/models/result";
import { GetOrderList } from "../../../domain/usages/get-order-list";
import NavigationModal from "../../components/common/navigation-modal";
import OrderList from "../../components/orders/order-list";
import { NavBar, NavColor } from "../../ga-components/nav";
import { TabModel } from "../../ga-components/tabs";

const FilterTabs = CATEGORY_LIST.map(
  (c) => ({ id: c.value, text: c.label, value: c.value } as TabModel)
);

type Props = {
  getOrderList: GetOrderList;
};
const OrderListPage: React.FC<Props> = ({ getOrderList }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<Order[]>([]);

  /**
   * Go to previous page
   */
  let goBack = () => {
    navigate(-1);
  };

  /** Fetch orders from server */
  const fetchOrders = useCallback(async () => {
    setLoading(true);
    let result: Result = await getOrderList.get({});
    if (result.orders) {
      setOrders(result.orders);
    }
    setLoading(false);
  }, [getOrderList]);

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <>
      <NavBar
        title={"Orders"}
        subTitle={"See status of all your orders here"}
        color={NavColor.WHITE}
        back={true}
        onBackClick={goBack}
        right={<NavigationModal />}
      />
      <div className="p-4 h-full">
        {orders && orders.length > 0 && <OrderList orders={orders} />}
      </div>
    </>
  );
};

export default OrderListPage;
