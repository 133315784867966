export const AUTH_TOKEN_HEADER = process.env.REACT_APP_AUTH_TOKEN_HEADER!;
export const AUTH_TOKEN_KEY = process.env.REACT_APP_AUTH_TOKEN_KEY!;
export const AUTH_AP_TOKEN_HEADER = process.env.REACT_APP_AUTH_AP_TOKEN_HEADER!;
export const AUTH_AP_TOKEN_KEY: string =
  process.env.REACT_APP_AUTH_AP_TOKEN_KEY!;
export const AUTH_USER: string = process.env.REACT_APP_AUTH_USER!;
export const AUTH_DOCTOR: string = process.env.REACT_APP_AUTH_DOCTOR!;
export const AUTH_USER_GROUP_TOKEN: string =
  process.env.REACT_APP_AUTH_USER_GROUP_TOKEN!;
export const AUTH_IDENTITY_TOKEN_KEY: string =
  process.env.REACT_APP_AUTH_IDENTITY_TOKEN_KEY!;
export const ORDER_AUTH_TOKEN: string = process.env.REACT_APP_ORDER_AUTH_TOKEN!;
export const GA_PRODUCT_HEADER = process.env.REACT_APP_GA_PRODUCT_HEADER!;
export const AUTH_TOKEN_STORAGE: string =
  process.env.REACT_APP_AUTH_TOKEN_STORAGE!;
export const USER_MOBILE: string = process.env.REACT_APP_USER_MOBILE!;
export const REACT_APP_USER_ACTIVITIES: string = process.env.USER_ACTIVITIES!;
export const ORDER_TOKEN: string = process.env.REACT_APP_ORDER_TOKEN!;
export const BASE_AUTH_API_ENDPOINT: string =
  process.env.REACT_APP_BASE_AUTH_API_ENDPOINT!;
export const BASE_AUTH_ROUTE: string = process.env.REACT_APP_BASE_AUTH_ROUTE!;
export const BASE_ORDER_API_ENDPOINT: string =
  process.env.REACT_APP_BASE_ORDER_API_ENDPOINT!;
export const BASE_ORDER_ROUTE: string = process.env.REACT_APP_BASE_ORDER_ROUTE!;
export const BASE_DOCTOR_API_ENDPOINT: string =
  process.env.REACT_APP_BASE_DOCTOR_API_ENDPOINT!;
export const BASE_DOCTOR_ROUTE: string =
  process.env.REACT_APP_BASE_DOCTOR_ROUTE!;
export const POSTALCODE_API_ENDPOINT: string =
  process.env.REACT_APP_POSTALCODE_API_ENDPOINT!;
export const WALLET_API_ENDPOINT: string =
  process.env.REACT_APP_WALLET_API_ENDPOINT!;
export const WALLET_API_V2_ENDPOINT: string =
  process.env.REACT_APP_WALLET_V2_API_ENDPOINT!;

export const WALLET_AUTH_TOKEN_HEADER: string =
  process.env.REACT_APP_WALLET_AUTH_TOKEN_HEADER!;

export const LOCATION_SERVICE_TOKEN: string =
  process.env.REACT_APP_LOCATION_SERVICE_TOKEN!;
