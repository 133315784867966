import React, { useState } from "react";
import withTheme from "../../providers/theme/withTheme";
import NavColor from "./nav-color";
import { useNavigate } from "react-router-dom";
import { Icon } from "../typography/icon";
import Clickable from "../clickable";
import { IconSize } from "../typography/icon/icon-size";

type Props = {
  theme: any;
  title: string;
  subTitle: string;
  color: NavColor;
  back: boolean;
  onBackClick?: Function;
  left?: React.ReactNode;
  right?: React.ReactNode;
  leftIcon?: string;
  className?: string;
};
const NavBar: React.FC<Props> = ({
  theme,
  title,
  subTitle,
  color,
  back,
  onBackClick,
  left,
  right,
  leftIcon,
  className,
}) => {
  const navigate = useNavigate();
  const cssClass = [
    theme.navBar.base,
    className,
    theme.navBar.colors[color],
    "top-0 sticky",
  ].join(" ");

  var leftContent: React.ReactNode;
  if (back && onBackClick) {
    leftContent = (
      <Clickable onClick={onBackClick}>
        <Icon
          className={theme.navBar.left}
          size={IconSize.xl}
          icon={leftIcon ? leftIcon : "arrowLeft"}
        />
      </Clickable>
    );
  } else {
    leftContent = left;
  }
  return (
    <div className={cssClass}>
      <div className="">{leftContent}</div>
      <div className="text-left mx-4 grow">
        <div className={theme.navBar.headline + " items-start"}>{title}</div>
        <div className={theme.navBar.subHeadline}>{subTitle}</div>
      </div>
      <div>{right}</div>
    </div>
  );
};

export default withTheme(NavBar);
