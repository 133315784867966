import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  EXPECTED_DELIVERY_DATES,
  SUBSCRIPTION_DURATIONS,
} from "../../../Constants";
import { CreateSubscription } from "../../../domain/usages/create-subscription";
import { GetAddressList } from "../../../domain/usages/get-address-list";
import { PanCardCheckFactory } from "../../../main/factories/PanCardCheckFactory";
import AddressSelection from "../../components/products/address-selection";
import ProductList from "../../components/products/product-list";
import SubscriptionButton from "../../components/products/subscription-button";
import {
  Card,
  CardBody,
  CardColor,
  CardSupportingText,
} from "../../ga-components/cards";
import { InputVariant } from "../../ga-components/inputs";
import ButtonSelectField from "../../ga-components/inputs/button-select-field";
import { Option } from "../../ga-components/inputs/option";
import SelectField from "../../ga-components/inputs/select-field";
import { NavBar, NavColor } from "../../ga-components/nav";
import { CartState } from "../../providers/cart/CartContext";
import withCart from "../../providers/cart/withCart";

type SubscriptionFormField = {
  duration: number;
  expected_delivery_date: number;
};

type Props = {
  getAddressList: GetAddressList;
  createSubscription: CreateSubscription;
  cart: CartState;
  cartOperationEventEmitter: Function;
};
const SubscriptionPage: React.FC<Props> = ({
  getAddressList,
  createSubscription,
  cart,
  cartOperationEventEmitter,
}) => {
  const navigate = useNavigate();
  const [token, setToken] = useState<string>();
  const [isAddressValid, setIsAddressValid] = useState(false);
  const {
    cart: { products },
  } = cart;
  const {
    control,
    getValues,
    watch,
    formState: { isValid },
  } = useForm<SubscriptionFormField>({
    mode: "all",
  });
  const duration = watch("duration");
  const expected_delivery_date = watch("expected_delivery_date");

  return (
    <>
      <NavBar
        title={"Subscription"}
        subTitle={""}
        color={NavColor.SECONDARY_WHITE}
        back={true}
        onBackClick={() => navigate(-1)}
      />
      {!token && <PanCardCheckFactory setToken={setToken} />}
      <div className="h-full bg-white overflow-auto pb-20">
        <AddressSelection
          onValidationUpdated={setIsAddressValid}
          variant={InputVariant.SECONDARY_FILLED}
          getAddressList={getAddressList}
        />
        <div className="divide-y w-full">
          <div></div>
          <div></div>
        </div>
        <div>
          <Card
            color={CardColor.SECONDARY_LOW}
            className="overflow-visible rounded-none"
          >
            <CardBody>
              <div className="mb-4 text-center">
                <Controller
                  name="duration"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <ButtonSelectField
                      value={
                        {
                          value: value ? value.toString() : undefined,
                          key: value ? value.toString() : undefined,
                        } as Option
                      }
                      onChange={(value: any) => onChange(value.value)}
                      options={SUBSCRIPTION_DURATIONS.map(
                        (s) =>
                          ({ key: s.toString(), value: s.toString() } as Option)
                      )}
                      variant={InputVariant.SECONDARY_FILLED}
                      error={error && error.message}
                      label="Choose your Subscription Period"
                      helpText="in Months"
                    />
                  )}
                  rules={{ required: { value: true, message: "Required" } }}
                />
              </div>
              <div className="divide-y w-full">
                <div></div>
                <div></div>
              </div>
              <div className=" mt-4  text-center">
                <Controller
                  name="expected_delivery_date"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <SelectField
                      className="mt-2"
                      value={
                        {
                          value: value ? value.toString() : undefined,
                          key: value ? value.toString() : undefined,
                        } as Option
                      }
                      onChange={(value: any) => onChange(value.value)}
                      options={EXPECTED_DELIVERY_DATES.map(
                        (s) =>
                          ({ key: s.name, value: s.value.toString() } as Option)
                      )}
                      variant={InputVariant.SECONDARY_FILLED}
                      error={error && error.message}
                      placeholder="Status"
                      label="Choose the expected delivery date"
                    />
                  )}
                  rules={{
                    required: { value: true, message: "Required" },
                  }}
                />
              </div>
            </CardBody>
          </Card>
        </div>
        <Card color={CardColor.WHITE} className="">
          <CardBody className="text-left pb-0">
            <CardSupportingText className="font-semibold">
              Order Summary{" "}
              {`(${products.length} product${products.length > 1 ? "s" : ""} )`}
            </CardSupportingText>
          </CardBody>
        </Card>
        <div className="p-4 pt-0">
          <div className="">
            <ProductList />
          </div>
        </div>

        <div className="fixed bottom-0 w-full left-0">
          <SubscriptionButton
            token={token}
            duration={duration}
            expected_delivery_date={expected_delivery_date}
            createSubscription={createSubscription}
            disabled={!isValid || !isAddressValid || products.length == 0}
          />
        </div>
      </div>
    </>
  );
};

export default withCart(SubscriptionPage);
