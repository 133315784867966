import {
  AUTH_TOKEN_HEADER,
  BASE_ORDER_API_ENDPOINT,
  AUTH_TOKEN_KEY,
  AUTH_USER_GROUP_TOKEN,
  GA_PRODUCT_HEADER,
} from "../../base";
import { RemoteGetOrderList } from "../../data/usercases/remote-get-order-list";
import { RemoteGetOrderedProductCodes } from "../../data/usercases/remote-get-ordered-sku-codes";
import { RemoteGetProductList } from "../../data/usercases/remote-get-product-list";
import { RemoteGetSubscribedProductCodes } from "../../data/usercases/remote-get-subscribed-sku-codes";
import { RemoteGetSubscriptionList } from "../../data/usercases/remote-get-subscription-list";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import ProductPage from "../../presentation/screens/order/ProductPage";

type Props = {};

export const ProductPageFactory = (props: Props) => {
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN_KEY);
  const userGroupToken = storage.get(AUTH_USER_GROUP_TOKEN);

  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({
    [AUTH_TOKEN_HEADER]: token,
    [GA_PRODUCT_HEADER]: userGroupToken,
  });

  const remoteGetProductList = new RemoteGetProductList(
    `${BASE_ORDER_API_ENDPOINT}/products`,
    axiosHttpClient
  );

  const getOrderedProductCodes = new RemoteGetOrderedProductCodes(
    `${BASE_ORDER_API_ENDPOINT}/orders/sku-codes`,
    axiosHttpClient
  );

  const getSubscribedProductCodes = new RemoteGetSubscribedProductCodes(
    `${BASE_ORDER_API_ENDPOINT}/subscriptions/sku-codes`,
    axiosHttpClient
  );

  return (
    <ProductPage
      getOrderedProductCodes={getOrderedProductCodes}
      getProductList={remoteGetProductList}
      storage={storage}
      getSubscribedProductCodes={getSubscribedProductCodes}
      {...props}
    />
  );
};
