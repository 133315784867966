import { Result } from "../../domain/models/result";
import { GetWalletTransactionList } from "../../domain/usages/get-wallet-transaction-list";
import { HttpGetClient } from "../protocols/http/http-get-client";

export class RemoteGetWalletTransactionList
  implements GetWalletTransactionList
{
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async get(
    token: string,
    params: GetWalletTransactionList.Params
  ): Promise<Result> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(":token", token),
      query: params,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      authHeaders: true,
    });

    return httpResponse.data;
  }
}
