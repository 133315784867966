import {
  AUTH_TOKEN_HEADER,
  AUTH_TOKEN_KEY,
  AUTH_USER,
  WALLET_API_ENDPOINT,
  WALLET_API_V2_ENDPOINT,
  WALLET_AUTH_TOKEN_HEADER,
} from "../../base";
import { RemoteCreateRewardRequest } from "../../data/usercases/remote-create-reward-request";
import { RemoteGetWalletBalance } from "../../data/usercases/remote-get-wallet-balance";
import { RemoteGetWalletTransactionList } from "../../data/usercases/remote-get-wallet-transaction-list";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import WalletPage from "../../presentation/screens/wallet/WalletPage";

type Props = {};

export const WalletPageFactory = (props: Props) => {
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN_KEY);
  const user: any = JSON.parse(storage.get(AUTH_USER) ?? JSON.stringify({}));
  let walletAuthToken = user.wallet_auth_token;

  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({
    [WALLET_AUTH_TOKEN_HEADER]: walletAuthToken,
  });

  const getWalletTransactionList = new RemoteGetWalletTransactionList(
    `${WALLET_API_V2_ENDPOINT}/account_transactions`,
    axiosHttpClient
  );

  const getWalletBalance = new RemoteGetWalletBalance(
    `${WALLET_API_V2_ENDPOINT}/accounts/totals`,
    axiosHttpClient
  );

  const createRewardRequest = new RemoteCreateRewardRequest(
    `${WALLET_API_ENDPOINT}/redeem_requests`,
    axiosHttpClient
  );

  return (
    <WalletPage
      createRewardRequest={createRewardRequest}
      walletToken={user.wallet_token}
      getWalletBalance={getWalletBalance}
      getWalletTransactionList={getWalletTransactionList}
      {...props}
    />
  );
};
