import { Result } from "../../domain/models/result";
import { VerifyOtp } from "../../domain/usages/verify-otp";
import { HttpPostClient } from "../protocols/http/http-post-client";

export class RemoteVerifyOtp implements VerifyOtp {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}

  async verify(params: VerifyOtp.Params): Promise<Result> {
    const httpResponse = await this.httpPostClient.post({
      url: this.url,
      body: params,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      authHeaders: false,
    });

    return httpResponse.data;
  }
}
