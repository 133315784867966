import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { JsonStorage } from "../../../data/protocols/storage/json-storage";
import { CreateOrder } from "../../../domain/usages/create-order";
import { GenerateOrderToken } from "../../../domain/usages/generate-order-token";
import { GetAddressList } from "../../../domain/usages/get-address-list";
import { PanCardCheckFactory } from "../../../main/factories/PanCardCheckFactory";
import AddressSelection from "../../components/products/address-selection";
import PlaceOrderButton from "../../components/products/place-order-button";
import ProductList from "../../components/products/product-list";
import {
  Card,
  CardBody,
  CardColor,
  CardSupportingText,
} from "../../ga-components/cards";
import { InputVariant } from "../../ga-components/inputs";
import { NavBar, NavColor } from "../../ga-components/nav";
import { CartState } from "../../providers/cart/CartContext";
import withCart from "../../providers/cart/withCart";

type Props = {
  getAddressList: GetAddressList;
  createOrder: CreateOrder;
  generateOrderToken: GenerateOrderToken;
  storage: JsonStorage;
  cart: CartState;
  cartOperationEventEmitter: Function;
};
const CartPage: React.FC<Props> = ({
  getAddressList,
  createOrder,
  generateOrderToken,
  storage,
  cart,
  cartOperationEventEmitter,
}) => {
  const navigate = useNavigate();
  const [token, setToken] = useState<string>();
  const [isAddressValid, setIsAddressValid] = useState(false);
  const {
    type,
    cart: { address, products },
  } = cart;

  return (
    <div className="bg-white h-full">
      <NavBar
        title={"Order Summary"}
        subTitle={""}
        color={NavColor.WHITE}
        back={true}
        onBackClick={() => navigate(-1)}
      />
      {!token && <PanCardCheckFactory setToken={setToken} />}

      <div className="h-full">
        <AddressSelection
          onValidationUpdated={setIsAddressValid}
          variant={InputVariant.FILLED}
          getAddressList={getAddressList}
        />
        <div className="divide-y w-full">
          <div></div>
          <div></div>
        </div>
        <Card color={CardColor.WHITE}>
          <CardBody className="text-left pb-0">
            <CardSupportingText className="font-semibold">
              Order Summary{" "}
              {`(${products.length} product${products.length > 1 ? "s" : ""} )`}
            </CardSupportingText>
          </CardBody>
        </Card>
        <div className="h-3/5 overflow-auto p-4 pt-0">
          <div className="">
            <ProductList />
          </div>
        </div>
        <div className="fixed bottom-0 w-full left-0">
          <PlaceOrderButton
            token={token}
            createOrder={createOrder}
            storage={storage}
            generateOrderToken={generateOrderToken}
            disabled={!isAddressValid}
          />
        </div>
      </div>
    </div>
  );
};

export default withCart(CartPage);
