import React from "react";

import Cart from "./Cart";
import { CartState } from "./CartContext";

export interface InjectedCartProps {
  cart: CartState;
  cartOperationEventEmitter: Function;
}

const withCart = <P extends InjectedCartProps>(
  Component: React.ComponentType<P>
) => {
  const WithCart: React.FC<Omit<P, keyof InjectedCartProps>> = (props) => (
    <Cart.Consumer>
      {({ state, dispatch }) => (
        <Component
          {...(props as P)}
          cart={state}
          cartOperationEventEmitter={dispatch}
        />
      )}
    </Cart.Consumer>
  );

  WithCart.displayName = `WithCart(${Component.displayName})`;
  return WithCart;
};

export default withCart;
