import React from "react";
import { CartState } from "../../providers/cart/CartContext";
import withCart from "../../providers/cart/withCart";
import ProductItem from "./product-item";

type Props = {
  cart: CartState;
  cartOperationEventEmitter: Function;
};

const ProductList: React.FC<Props> = ({ cart, cartOperationEventEmitter }) => {
  const {
    cart: { products },
  } = cart;
  return (
    <ul>
      {products &&
        products.length > 0 &&
        products.map((p) => <ProductItem product={p} />)}
    </ul>
  );
};

export default withCart(ProductList);
