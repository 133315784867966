import { Address } from "./address";
import { Card } from "./card";
import { Order } from "./order";
import { PostOffice } from "./post-office";
import { Product } from "./product";
import { Subscription } from "./subscription";
import { Token } from "./token";
import { WalletBalance } from "./wallet-balance";
import { WalletTransaction } from "./wallet-transaction";

type Errors = {
  message?: string;
  amount?: number;
};

export interface Result {
  products: Product[];
  cards: Card[];
  addresses: Address[];
  PostOffice: PostOffice[];
  orders: Order[];
  product_group_token: string;
  user: any;
  doctor: {};
  subscriptions: Subscription[];
  sku_codes: string[];
  tokens: Token[];
  errors: Errors;
  success: boolean;
  verification_status: string;
  utilized_limit: string;
  token: string;
  total_amount: number;
  total_pending_amount: number;
  total_converted: number;
  account_transactions: WalletTransaction[];
  url: string | null;
  data: any[];
}

export const EmptyResponse: Result = {
  products: [],
  cards: [],
  addresses: [],
  PostOffice: [],
  sku_codes: [],
  tokens: [],
  user: {},
  doctor: {},
  total_amount: 0,
  total_pending_amount: 0,
  total_converted: 0,
  orders: [],
  product_group_token: "",
  subscriptions: [],
  errors: {} as Errors,
  success: false,
  verification_status: "",
  utilized_limit: "",
  token: "",
  account_transactions: [],
  url: null,
  data: [],
};
