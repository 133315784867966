import React from "react";
import ReactModal from "react-modal";
import { Price } from "../../../domain/models/price";
import { Product } from "../../../domain/models/product";
import {
  Card,
  CardBody,
  CardColor,
  CardSubHeadline,
  CardSupportingText,
} from "../../ga-components/cards";
import PriceList from "./price-list";

type Props = {
  product: Product;
  open: boolean;
  onClick: Function;
  close: Function;
};

const PriceListModal: React.FC<Props> = ({
  product,
  open = false,
  onClick,
  close,
}) => {
  return (
    <ReactModal
      isOpen={open}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.25)",
          display: "flex",
          justifyContent: "center",
        },
        content: {
          position: "relative",
          margin: "auto",
          padding: 0,
          inset: "auto",
        },
      }}
      onRequestClose={() => close()}
      contentLabel="Example Modal"
    >
      <Card color={CardColor.WHITE} className="m-auto w-full">
        <CardBody>
          <CardSubHeadline>Select UOM</CardSubHeadline>
          <CardSupportingText>
            Please select which uom would you like to go with.
          </CardSupportingText>
        </CardBody>
        <PriceList
          prices={product.prices}
          onSelect={(price: Price) => onClick(price)}
        ></PriceList>
      </Card>
    </ReactModal>
  );
};

export default PriceListModal;
