import React from "react";
import InputVariant from "./../input-variant";
import InputError from "./input-error";
import InputHelpText from "./input-help-text";
import withTheme from "../../../providers/theme/withTheme";

type Props = {
  theme: any;
  variant?: InputVariant;
  error?: string;
  label?: string;
  className?: string;
  children: any;
  helpText?: string;
};
const InputGroup: React.FC<Props> = ({
  theme,
  label,
  error = "",
  variant = InputVariant.FILLED,
  className = "",
  children,
  helpText,
}) => {
  const classes = [className];

  classes.push(theme.input[variant].label);
  return (
    <div className={classes.join(" ")}>
      {label && <label>{label}</label>}
      {children}
      {error && <InputError message={error} variant={variant} />}
      {helpText && <InputHelpText message={helpText} variant={variant} />}
    </div>
  );
};

export default withTheme(InputGroup);
