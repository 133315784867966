import React from "react";

type Props = {
  onClick: Function;
  disabled?: boolean;
  children: React.ReactNode;
};

const Clickable: React.FC<Props> = ({
  onClick,
  disabled = false,
  children,
}) => {
  return (
    <div
      onClick={(e) => {
        if (disabled) return;
        e.preventDefault();
        onClick();
      }}
    >
      {children}
    </div>
  );
};

export default Clickable;
