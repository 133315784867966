import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardColor,
  CardRightContent,
  CardSubHeadline,
  CardSupportingText,
} from "../../ga-components/cards";
import Clickable from "../../ga-components/clickable";
import { Icon, IconColor } from "../../ga-components/typography/icon";
import { IconSize } from "../../ga-components/typography/icon/icon-size";
import { CartState, CartType } from "../../providers/cart/CartContext";
import withCart from "../../providers/cart/withCart";

type Props = {
  cart: CartState;
  cartOperationEventEmitter: Function;
};

const GoToCardButton: React.FC<Props> = ({
  cart,
  cartOperationEventEmitter,
}) => {
  const navigate = useNavigate();
  const {
    type,
    cart: { products },
  } = cart;

  const onClick = () => {
    if (type == CartType.sample) {
      navigate("/cart");
    } else if (type == CartType.subscription) {
      navigate("/subscriptions/summary");
    }
  };
  if (products.length == 0 || !type) return <></>;

  if (type == CartType.subscription) {
    return (
      <Clickable onClick={onClick}>
        <Card color={CardColor.SECONDARY} className="rounded-none">
          <CardBody className="p-2 text-center">
            <CardSubHeadline className="text-white">Checkout</CardSubHeadline>
            <CardSupportingText className="text-gray-100">
              {`(${products.length} product${
                products.length > 1 ? "s" : ""
              } added to cart)`}
            </CardSupportingText>
            <CardRightContent className="text-right">
              <Icon
                icon="arrowRight"
                color={IconColor.WHITE}
                size={IconSize.xl}
              />
            </CardRightContent>
          </CardBody>
        </Card>
      </Clickable>
    );
  }
  return (
    <Clickable onClick={onClick}>
      <Card color={CardColor.PRIMARY} className="rounded-none">
        <CardBody className="p-2 text-center">
          <CardSubHeadline className="text-white">Checkout</CardSubHeadline>
          <CardSupportingText className="text-gray-100">
            {`(${products.length} product${
              products.length > 1 ? "s" : ""
            } added to cart)`}
          </CardSupportingText>
          <CardRightContent className="text-right">
            <Icon
              icon="arrowRight"
              color={IconColor.WHITE}
              size={IconSize.xl}
            />
          </CardRightContent>
        </CardBody>
      </Card>
    </Clickable>
  );
};

export default withCart(GoToCardButton);
