import React from "react";
import withTheme from "../../providers/theme/withTheme";
import { IconColor } from "../typography/icon";
import Icon from "../typography/icon/icon";
import { IconSize } from "../typography/icon/icon-size";
import { min } from "lodash";

type Props = {
  theme: any;
  value: number;
  maxQuantity?: number;
  minQuantity?: number;
  onChange: Function;
};
const QuantityButton: React.FC<Props> = ({
  theme,
  value,
  maxQuantity = 0,
  minQuantity = 1,
  onChange,
}) => {
  const increment = () => {
    if (value < maxQuantity) onChange(value + 1);
  };

  const decrement = () => {
    if (value > minQuantity) onChange(value - 1);
  };

  return (
    <div className="flex h-10 w-full items-center justify-center content-center text-primary">
      <div className="basis-1/3 text-center">
        <button
          className={`w-7 h-7 rounded-full m-auto text-center border-1 ${
            minQuantity < value ? "text-gray-600" : "text-gray-300"
          }`}
          onClick={decrement}
        >
          <Icon icon="minus" color={IconColor.DANGER} />
        </button>
      </div>

      <div className="text-center basis-1/3">{value}</div>
      <div className="basis-1/3 text-center">
        <button
          className={`w-7 h-7 rounded-full m-auto text-center border-1 ${
            maxQuantity > value ? "text-gray-600" : "text-gray-300"
          }`}
          onClick={increment}
        >
          <Icon icon="add" color={IconColor.DANGER} size={IconSize.sm} />
        </button>
      </div>
    </div>
  );
};

export default withTheme(QuantityButton);
