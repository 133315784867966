import {
  AUTH_TOKEN_HEADER,
  AUTH_TOKEN_KEY,
  BASE_ORDER_API_ENDPOINT,
} from "../../base";
import { RemoteGetOrderList } from "../../data/usercases/remote-get-order-list";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import OrderListPage from "../../presentation/screens/order/OrderListPage";

type Props = {};

export const OrderListPageFactory = (props: Props) => {
  const storage = LocalJsonStorage.getInstance();
  const token = storage.get(AUTH_TOKEN_KEY);

  const axiosHttpClient = AxiosHttpClient.getInstance();
  axiosHttpClient.setAuthHeaders({
    [AUTH_TOKEN_HEADER]: token,
  });

  const remoteGetOrderList = new RemoteGetOrderList(
    `${BASE_ORDER_API_ENDPOINT}/orders`,
    axiosHttpClient
  );
  return <OrderListPage getOrderList={remoteGetOrderList} {...props} />;
};
