import { Result } from "../../domain/models/result";
import { GetDoctor } from "../../domain/usages/get-doctor";
import { HttpGetClient } from "../protocols/http/http-get-client";

export class RemoteGetDoctor implements GetDoctor {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async get(params: GetDoctor.Params): Promise<Result> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url,
      query: params,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      authHeaders: true,
    });

    return httpResponse.data;
  }
}
