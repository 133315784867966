import React from "react";
import { Order } from "../../../domain/models/order";
import OrderItem from "./order-item";

type Props = {
  orders: Order[];
};

const OrderList: React.FC<Props> = ({ orders }) => {
  return (
    <ul>
      {orders &&
        orders.length > 0 &&
        orders.map((o) => <OrderItem order={o} />)}
    </ul>
  );
};

export default OrderList;
