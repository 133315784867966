import React from "react";
import { Button, ButtonColor, ButtonType } from "../../ga-components/buttons";
import { Icon, IconColor } from "../../ga-components/typography/icon";
import { IconSize } from "../../ga-components/typography/icon/icon-size";

type Props = {
  mandatory: boolean;
  skip: () => void;
};

const PanCardProcessing: React.FC<Props> = ({ mandatory, skip }) => {
  return (
    <>
      <div className="relative z-10" role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <Icon
                      icon="warning"
                      color={IconColor.WHITE}
                      size={IconSize.xl}
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="modal-title"
                    >
                      Your PAN Card is under verification
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Under 194R of Income Tax Act, 1961, it is mandatory to
                        verify your PAN Card. We are currently verifying your
                        PAN Card. You will be notified once your PAN Card is
                        verified.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-5">
                <div className="grid grid-rows-1 grid-flow-col gap-4">
                  <div>
                    {!mandatory && (
                      <Button
                        className="w-full"
                        color={ButtonColor.WHITE}
                        type={ButtonType.TEXT}
                        text="SKIP"
                        onClick={() => skip()}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PanCardProcessing;
